import { get } from 'svelte/store';
import { event } from './stores.js';
import { isYetToCome } from "./dt.js";

export const walkInsAvailable = () => {

	let evt = get(event);
	let available = false;

	if (evt && evt.ticketTypes) {
		for (const tt of evt.ticketTypes) {
			if (
				((evt.available == null) || (evt.available))
				&& !tt.registrationExpired
				&& !tt.soldOut
				&& !tt.invitationOnly
				&& (!tt.validFrom || !isYetToCome(tt.validFrom))
				&& (!tt.validTo || isYetToCome(tt.validTo))
			) {
				// at least one ticket type is available to buy and insta-checkin
				available = true;
				break;
			}
		}
	}

	return available;

}