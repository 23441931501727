<script>

	import { onMount } from 'svelte';
	import { tweened } from 'svelte/motion';
	import { cubicOut } from 'svelte/easing';

	import { liveQuery } from "dexie";

	import RadialProgress from "../ui/RadialProgress.svelte";

	import { settings, event } from '../lib/stores.js';
	import { db } from "../lib/db.js";

	let mounted = false;

	let attendees = liveQuery(
		() => db.attendees.filter(r => {	return !r.isVirtual }).count()
	);

	let checkedin = liveQuery(
		() => db.attendees.where('status').equals('checked-in').filter(r => { return !r.isVirtual }).count()
	);

	const percentage = tweened(0, {
		duration: 600,
		easing: cubicOut
	});

	function calcPercentage() {
		let p = ($checkedin / $attendees) * 100;
		if (isNaN(p)) {
			$percentage = 0;
		} else if ((Number(p) > 0) && (Number(p) < 3)) {
			$percentage = 3;
		} else {
			$percentage = Number(p);
		}
	}

	$: if ($event && $event.ref) {
		mounted = true;
	}

	$: calcPercentage($attendees, $checkedin);

</script>

<style>
	.microstats > :global(div) {
		position: absolute;
		inset: 8px;
		width: auto;
		height: auto;
		display: grid;
		place-content: center;
	}
	.microstats :global(svg) {
		width: auto !important;
		height: auto !important;
	}
</style>

<div class="microstats">
	{#if mounted}
		<RadialProgress value={$percentage} micro={true}/>
	{/if}
</div>