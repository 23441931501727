<script>

	import { onMount } from 'svelte';

	import cssVars from 'svelte-css-vars';

	import { event } from '../../lib/stores.js';

	export let bg;

	let bgVars = {};
	let mounted = false;

	const cdnurl = 'https://cdn.attendzen.io';

	onMount(() => {
		bgVars.color = $event.theme.colors[bg.color];
		bgVars.aspectRatio = bg.image?.aspectRatio;
		bgVars.focalPoint = `${bg.image?.focalPointX}% ${bg.image?.focalPointY}%`;
		if (bg.imagePresentation == 'tinted') {
			if (bg.image.format == 'svg') {
				bgVars.mask = `url('${cdnurl}/${$event.accountRef}/mask_${bg.image.filename.replace(/\.svg$/,'.png')}')`;
			} else {
				bgVars.mask = `url('${cdnurl}/${$event.accountRef}/mask_${bg.image.filename.replace(/\.jpe?g$/,'.png')}')`;
			}
		}
		if (bg.tintColor) {
			bgVars.tintColor = $event.theme.colors[bg.tintColor];
		}
		if (bg.overlayStyle == 'tint') {
			bgVars.overlayColor = $event.theme.colors[bg.overlayColor];
		} else {
			if (bg.image?.dominantColor) {
				bgVars.overlayColor = bg.image.dominantColor;
			} else {
				bgVars.overlayColor = '#f5f7f9';
			}
		}
		bgVars.overlayOpacity = bg.overlayOpacity;
		mounted = true;
	});

</script>

<div
	class="c_bg {bg.type} {bg.imagePresentation} {bg.image?.type} {bg.overlayStyle} {bg.blendMode}"
	class:opaque={bg.overlayOpacity == '100'}
	use:cssVars={bgVars}
>
	{#if bg.type == 'image'}
		<div class="ic_bg">
			{#if bg.image?.filename && (bg.imagePresentation == 'tinted') && (bg.image?.format == 'svg')}
				<img crossorigin="anonymous" src="{cdnurl}/{$event.accountRef}/mask_{bg.image.filename.replace(/\.svg$/,'.png')}" alt="" />
			{:else if bg.image?.filename && (bg.imagePresentation == 'tinted')}
				<img crossorigin="anonymous" src="{cdnurl}/{$event.accountRef}/mask_{bg.image.filename.replace(/\.jpe?g$/,'.png')}" alt="" />
			{:else if bg.image?.filename && (bg.image?.format == 'svg')}
				<img crossorigin="anonymous" src="{ cdnurl }/{ $event.accountRef }/{ bg.image.filename }" alt="" />
			{:else if bg.image?.filename}
				<img crossorigin="anonymous" src="{ cdnurl }/{ $event.accountRef }/{ bg.image.type == 'logo' ? 'trim' : 'max' }_{ bg.image.filename }" alt="" />
			{:else if bg.image?.unsplash}
				<img crossorigin="anonymous" src="{ bg.image.unsplash.url }&amp;w=1200" alt="" />
			{/if}
		</div>
	{/if}
</div>