<script>

	import { onMount } from 'svelte';

	import { showDate } from "../lib/dt.js";
	import { attendee, crmFields, countryOpts, displayLang } from '../lib/stores.js';

	export let field;

	let mounted = false;

	onMount(() => {
		
		if (field.component) {
			if ((field.component.component == 'SelectList') || (field.component.component == 'CheckboxGroup') || (field.component.component == 'RadioButtons')) {
				if (!field.component.options && field.component.crm) {
					for (const c of $crmFields) {
						if (c.ref == field.component.crm) {
							field.component.options = c.options;
							break;
						}
					}
				}
			}
		}

		mounted = true;

	});

</script>

{#if mounted}
	{#if ($attendee[field.id] !== undefined) && (field.component?.component != 'TicketType')}
		<p>
			<span>{field.value}</span>
			{#if ($attendee[field.id] == null) || ($attendee[field.id] == '')}
				<strong>—</strong>
			{:else}
				{#if field.component?.component == 'DateField'}
					<strong>{showDate($attendee[field.id], 'DD MMM YY')}</strong>
				{:else if field.component?.component == 'Country'}
					{#each $countryOpts as opt}
						{#if opt.ref == $attendee[field.id]}
							<strong>{opt.name[$displayLang]}</strong>
						{/if}
					{/each}
				{:else if field.component?.options}
					{#each field.component.options as opt}
						{#if opt.ref == $attendee[field.id]}
							<strong>{opt.name[$displayLang]}</strong>
						{/if}
					{/each}
				{:else}
					<strong>{$attendee[field.id]}</strong>
				{/if}
			{/if}
		</p>
	{/if}
{/if}