import { get } from 'svelte/store';
import { vips } from './stores.js';

export const isVIP = (attendeeRef) => {

	const vipsData = get(vips);

	if (vipsData) {
		for (const vip of vipsData) {
			if (vip.attendee == attendeeRef) {
				return vip;
			}
		}
	}

	return null;

}