<script>

	// Buttons for the search view in staffed (event) mode

	import ButtonGroup from '../ui/ButtonGroup.svelte';
	import Button from '../ui/Button.svelte';

	import { walkInsAvailable } from "../lib/ticketTypes.js";
	import { screen, settings, showRegform, event, offline } from '../lib/stores.js';

</script>

<style>
</style>

<ButtonGroup>
	{#if $settings.qr}
		<Button label="Scan QR code" shortlabel="Scan QR" on:click={() => { $screen = 'qr' }}>
			<svg viewBox="0 0 80 80"><path d="M47 47h7v7h-7zM54 54h7v7h-7zM56.6 23.4h-12.2v12.2h12.2v-12.2zm4.4 16.6h-14v7h-14v-14h7v-14h21v21zM19 19h14v14h-14zM33 61h-14v-14h14v14zm-3.5-10.5h-7v7h7v-7z"/></svg>
		</Button>
	{/if}

	{#if $event?.setup?.checkin?.allowRegistrations && $settings.allowRegistrations && !$offline && walkInsAvailable()}
		<Button label="Register attendee" shortlabel="Register" ghost={true} on:click={() => { $showRegform = true }}>
			<svg viewBox="0 0 80 80"><path d="M34.5 67.8l-7.1-8.4 1.5-1.3c.7-.6 1.2-1.5 1.3-2.4v-.9l-.1-.9 3.7-1.7.3 1.4c.2.8.2 1.6.2 2.4-.1 1.3-.6 2.6-1.3 3.7l2.1 2.5 24-20.2-.9-1.1 3.8-1.8 2.8 3.4-30.3 25.3zM62 35.9l-.4-.8-3.1-6.6-.4-.8-.1-.3-.3.1-.7.4-.7.3c-.5.2-1 .3-1.5.3-.4 0-.8-.1-1.2-.2-.9-.3-1.6-1-2-1.9-.4-.9-.4-1.8-.1-2.7.3-.9 1-1.6 1.9-2l.7-.3.8-.4.3-.1-.1-.3-.4-.8-3-6.5-.4-.8-.3-.3-.3.1-.8.4-33.6 15.7-.8.4-.3.1.1.3.4.8 3.1 6.6.4.8.1.3.3-.1.8-.4.7-.3c.5-.2 1-.3 1.5-.3.4 0 .8.1 1.2.2.9.3 1.6 1 2 1.9.4.9.4 1.8.1 2.7-.3.9-1 1.6-1.9 2l-.7.3-.6.3-.3.1.1.3.4.8 3 6.5.4.8.1.3.3-.1.8-.4 33.6-15.7.8-.4.3-.1-.2-.2zm-33.8 12l-1-2.2c1.1-.8 1.9-1.9 2.3-3.2.7-1.8.6-3.8-.2-5.6-.8-1.8-2.3-3.1-4.1-3.8-.8-.3-1.6-.4-2.5-.4-.5 0-1 .1-1.5.2l-1.1-2.3 29.1-13.6 1 2.2c-1.1.8-1.9 1.9-2.3 3.2-.7 1.8-.6 3.8.2 5.6.8 1.8 2.3 3.1 4.1 3.8.8.3 1.6.4 2.5.4.5 0 1-.1 1.5-.2l1.1 2.3-29.1 13.6z"/></svg>
		</Button>
	{/if}
</ButtonGroup>