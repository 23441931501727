<script>

	import { displayLang } from '../../lib/stores.js';

	export let ref;
	export let value;
	export let options;
	export let disabled;

</script>

<style>
	.checkboxes p {
		text-align: left;
		margin: 0.8rem 0 0 0;
		display: flex;
		align-items: flex-start;
		gap: 0.4rem;
	}
	.checkboxes span {
		width: 1.2em;
		height: 1.2em;
		position: relative;
		overflow: visible;
		top: -0.1em;
	}
	.checkboxes span input {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		padding: 0;
		margin: 0;
		opacity: 0;
		z-index: 2;
	}
	.checkboxes span svg {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		opacity: 1;
		z-index: 1;
	}

	.checkbox path {
		fill: currentColor;
		transition: opacity 0.2s ease-in-out;
	}

	.checkbox path.on-tick {
		fill: var(--bgColor);
	}

	.checkbox input + svg .on {
		opacity: 0;
	}

	.checkbox input:checked + svg .on {
		opacity: 1;
	}

	.checkboxes label {
		display: inline-block;
		font-size: 1rem;
		line-height: 1;
	}

</style>

<div class="checkboxes">
	{#each options as o,i}
		<p>
			<span class="checkbox">	
				<input
					type="checkbox"
					id="{ref}_o{i}"
					value={o.ref ? o.ref : o.value}
					bind:group={value}
					{disabled}
				/>
				<svg viewBox="0 0 50 50"><g class="on"><path class="on-bg" d="M0 4c0-2.21 1.79-4 4-4h42c2.21 0 4 1.79 4 4v41.99c0 2.21-1.79 4-4 4h-42c-2.21 0-4-1.79-4-4v-41.99z"/><path class="on-tick" d="M20.33 38.22l-12.21-12.21 4.24-4.24 7.97 7.97 17.2-17.2 4.24 4.25z"/></g><path class="off" d="M0 4c0-2.21 1.79-4 4-4h42c2.21 0 4 1.79 4 4v41.99c0 2.21-1.79 4-4 4h-42c-2.21 0-4-1.79-4-4v-41.99zm44 42c1.1 0 2-.9 2-2v-38.01c0-1.1-.9-2-2-2h-38c-1.1 0-2 .9-2 2v38.01c0 1.1.9 2 2 2h38z"/></svg>
			</span>
			<label for="{ref}_o{i}">{o.name ? o.name[$displayLang] : o.label}</label>
		</p>
	{/each}
</div>




