<script>

	import { onMount, onDestroy, createEventDispatcher } from 'svelte';

	import { IdleSessionTimeout } from "idle-session-timeout";

	import Button from './ui/Button.svelte';
	import Keyboard from './ui/Keyboard.svelte';

	import {
		busy,
		offline,
		bigError,
		modal,
		unlockCode,
		screen,
		showSettings,
		event,
		appElement,
		attendee
	} from './lib/stores.js';

	import { getAuth, getServerData } from './lib/prelude.js';

	const dispatch = createEventDispatcher();

	export let unlocked = false;

	let code = '';
	let inputs;

	let session = new IdleSessionTimeout(20 * 1000); // 20s

	onMount(() => {
		if ($unlockCode) session.start();
	});

	onDestroy(() => {
		session.dispose();
	});
 
	session.onTimeOut = () => {
		console.log('unlock timeout');
		$showSettings = false;
	};

	// session.onTimeLeftChange = (timeLeft) => {
	// 	console.log(`${timeLeft} ms left`);
	// };

	function press(e) {
		const key = e.detail;
		if (key == 'enter') {
			checkCode();
		} else if (key == 'backspace') {
			if (code.length > 0) {
				code = code.slice(0, -1);
			}
		} else {
			if (code.length < 6) {
				code = code + key;
				checkCode();
			}
		}
	}

	async function checkCode() {

		code = code.toUpperCase();

		if (code.length == 6) {
			if (code == 'AZN000') {

				$appElement.classList.add('fade');

				setTimeout(() => {
					$unlockCode = undefined;
					$showSettings = false;
					setTimeout(() => {
						$appElement.classList.remove('fade');
					}, 400);
				}, 400);

			} else if (code == 'AZN111') {

				// Trigger refresh

				$appElement.classList.add('fade');

				setTimeout(() => {
					let u = $unlockCode;
					$unlockCode = undefined;

					setTimeout(() => {
						$unlockCode = u;
						$showSettings = false;
					}, 200);
				}, 400);

			} else if (code == $unlockCode) {

				// inputs.classList.add('correct');

				setTimeout(() => {
					unlocked = true;
					$screen = null;
					$attendee = null;
				}, 400);

			} else if ($offline) {

				session.dispose();

				$modal = {
					message: 'You’re currently offline.',
					details: [
						'Switching to a new event requires an internet connection.'
					],
					opts: [
						{
							name: "OK",
							display: {
								mini: true
							},
							f: () => {
								session.start();
								code = '';
							}
						},
					]
				};

			} else {

				if ($unlockCode) {

					$busy = true;

					const authData = await getServerData('/checkin/auth', {
						unlockCode: code
					});

					if (!$bigError) {

						if (authData && authData.eventName) {

							session.dispose();

							$modal = {
								message: 'This unlock code is for ' + authData.eventName + '.',
								details: [
									'If you go ahead, you will remove the attendee data for ' + $event.name + ' from this device and replace it with data for ' + authData.eventName + '.',
									'Are you sure you want to proceed?'
								],
								opts: [
									{
										name: "Proceed",
										display: {
											red: true,
											mini: true
										},
										f: () => {
											$appElement.classList.add('fade');
											dispatch('reset');
											setTimeout(() => {
												authorize();
											}, 400);
										}
									},
									{
										name: "Cancel",
										display: {
											ghost: true,
											mini: true
										},
										f: () => {
											session.start();
											code = '';
										}
									},
								]
							};

						} else {

							inputs.classList.add('shook');

							setTimeout(() => {
								inputs.classList.remove('shook');
							}, 700);

						}
					}

					console.log('unsetting busy (checkCode)', $busy);
					$busy = false;					

				} else {

					authorize();

				}

			}
		}
	}

	async function authorize() {

		$busy = true;

		session.dispose();

		let success = await getAuth(code);

		if (success) {

			// Don’t cancel $busy here -- we want it for sync()

			$appElement.classList.add('fade');

			setTimeout(() => {
				unlocked = true;
				$screen = null;
				$attendee = null;
			}, 400);

		} else {

			$busy = false;

			inputs.classList.add('shook');

			setTimeout(() => {
				inputs.classList.remove('shook');
			}, 700);

			if ($unlockCode) session.start();

		}

	}

</script>

<style>
	.unlock {
		font-family: 'Inter', sans-serif;
		display: flex;
		height: 100%;
		flex-direction: column;
		justify-content: space-between;
		margin-left: -1rem;
		margin-right: -1rem;
	}
	.unlock > div {
		margin: auto 0;
	}
	p {
		padding: 1rem 0 0 0;
		text-align: center;
	}
	label {
		color: var(--labelColor);
	}
	.code {
		margin: 2rem 0;
		display: flex;
		gap: 0.5rem;
		justify-content: center;
		user-select: none;
	}
	.code > div {
		--size: min(14vw, 8vh, 80px);
		border: 1px solid var(--textColor);
		width: var(--size);
		height: var(--size);
		font-size: calc(var(--size) * 0.8);
		font-weight: 700;
		display: grid;
		align-content: center;
		line-height: 0;
		text-align: center;
	}
	.code > div:nth-child(3) {
		margin-right: 1rem;
	}
	.unlock :global(.shook) {
		animation: shake 0.6s cubic-bezier(.36,.07,.19,.97) both;
		transform: translate3d(0, 0, 0);
		backface-visibility: hidden;
		perspective: 1000px;
	}
	.unlock :global(.shook > div) {
		border-color: #e2261e;
	}
	/*.unlock :global(.correct > div) {
		border-color: #77b82a;
	}*/

	@keyframes shake {
	  10%, 90% {
	    transform: translate3d(-1px, 0, 0);
	  }
	  
	  20%, 80% {
	    transform: translate3d(2px, 0, 0);
	  }

	  30%, 50%, 70% {
	    transform: translate3d(-4px, 0, 0);
	  }

	  40%, 60% {
	    transform: translate3d(4px, 0, 0);
	  }
	}
</style>

<div class="unlock" style="--font: 'Inter', sans-serif;">
	<div>
		<p>
			<label for="code">Please enter your unlock code:</label>
		</p>
		<div class="code" bind:this={inputs}>
			<div>{code.charAt(0)}</div>
			<div>{code.charAt(1)}</div>
			<div>{code.charAt(2)}</div>
			<div>{code.charAt(3)}</div>
			<div>{code.charAt(4)}</div>
			<div>{code.charAt(5)}</div>
		</div>
	</div>
	<Keyboard on:press={press}/>
</div>
