<script>

	import { onMount, tick } from 'svelte';
	import { fade } from 'svelte/transition';

	import * as Sentry from "@sentry/browser";

	import cssVars from 'svelte-css-vars';

	import InstallMessage from './InstallMessage.svelte';
	import Settings from './Settings.svelte';
	import Branding from './Branding.svelte';
	import Splash from './Splash.svelte';
	import QR from './QR.svelte';
	import Search from './Search.svelte';
	import Register from './Register.svelte';
	import Attendee from './Attendee.svelte';
	import Message from './Message.svelte';

	import Button from './ui/Button.svelte';
	import Modal from './ui/Modal.svelte';
	import Spinner from './ui/Spinner.svelte';
	import BigErrorModal from './ui/BigErrorModal.svelte';

	import {
		busy,
		bigError,
		modal,
		offline,
		lastOnline,
		appElement,
		unlockCode,
		showSettings,
		screen,
		event,
		attendee,
		showRegform,
		settings,
		vips,
		splash,
		deviceRef,
		deviceName,
		logoSize,
		templateHashtags,
		templates,
		unbranded,
		message,
		isNarrowScreen,
		countryOpts,
		socialMediaServiceOpts,
		displayLang,
		showOfflineBadgeModal,
		crmFields
	} from './lib/stores.js';

	import { getServerData, postServerData } from './lib/prelude.js';
	import { db } from "./lib/db.js";
	import { isYetToCome } from "./lib/dt.js";
	import { isVIP } from "./lib/vips.js";
	import { colorMix, getErrorColor, getSuccessColor, bestContrast, isPale, getBrightness } from './lib/color.js';
	import { isDesktop } from "./lib/device.js";

	let mounted = false;
	let showInstallMessage = false;

	let refresher;
	let twilioLoaded = false;
	let syncClient = null;
	let syncToken;
	let syncChannel;
	let splashDt;
	let themeVars = {};
	let font = '';

	onMount(async () => {

		await db.open().catch(() => {
			$bigError = 'The app database is unavailable. This may happen if you’re using private browsing mode.';
			mounted = false;
		});

		if (!$bigError) {

			window.addEventListener('offline', (e) => {
				$offline = true;
				$showOfflineBadgeModal = true;
				$lastOnline = new Date;
			});

			window.addEventListener('online', (e) => {
				$offline = false;
				$lastOnline = new Date;
			});

			setIsNarrowScreen();

			if (!$unlockCode) await resetEvent();

			mounted = true;

			// await tick();

			// if ($unlockCode) {
			// 	$appElement.classList.add('fade');
			// }

			if (!$countryOpts) {
				const countryData = await getServerData('/sites/countries', { lang: $displayLang });
				$countryOpts = countryData.countries;
			}

			if (!$socialMediaServiceOpts) {
				const socialMediaServiceData = await getServerData('/sites/socialmediaservices');
				$socialMediaServiceOpts = socialMediaServiceData.socialmediaservices;
			}

		}

	});

	async function resetEvent() {

		console.log('resetEvent');

		// don't flush the offline log tho...

		clearInterval(refresher);

		// $showSettings = false;
		$showRegform = false;
		$screen = undefined;
		$attendee = null;
		$unlockCode = '';

		if (syncClient) {
			await syncClient.shutdown();
			syncClient = null;
		}

		syncToken = null;
		syncChannel = null;

		db.attendees.clear();

		$settings = {
			deviceName: $deviceName,
			mode: 'event',
			staffed: true,
			print: null,
			session: null,
			qr: true,
			search: true
		};

		$event = {
			theme: {
				checkin: {
					"bgColor": "#101820",
					"textColor": "#ffffff",
					"accentColor": "#e5077e",
					"labelColor": "#ffffff"
				}
			}
		};

		$vips = null;
		$splash = null;

		$modal = null;

	}

	async function sync(inBackground) {

		if (!$offline) {

			console.log('Getting data for ' + $unlockCode, inBackground);

			clearInterval(refresher);

			let payload;

			if (!inBackground) {
				$busy = true;
			}

			if ($event && $event.ref) {

				let offlineData = await db.offline.where("event").equals($event.ref).toArray();

				if (offlineData.length) {
					const json = JSON.stringify(offlineData);
					payload = await postServerData('/checkin/sync', {
						offlineData: json
					});
				} else {
					payload = await getServerData('/checkin/sync');
				}

			} else {

				payload = await getServerData('/checkin/sync');

			}

			if (!$bigError && payload) {

				if ($event && $event.ref) {
					const deleted = await db.offline.where("event").equals($event.ref).delete();
					console.log("Deleted offline records:", deleted);
				}

				if (payload.event) {
					let evt = payload.event;
					if (evt?.theme?.checkin) {
						for (const [key, value] of Object.entries(evt.theme.checkin)) {
							if (value && (key != 'logo') && (key != 'logoStyle') && (key != 'logoSize') && !value.startsWith('#')) {
								evt.theme.checkin[key] = evt.theme.colors[value];
								// console.log(key, getBrightness(evt.theme.checkin[key]));
							}
						}
					}
					$event = evt;
				}

				db.attendees.clear();

				let bulk = [];

				if (payload?.attendees) {
					for (const a of payload.attendees) {

						// console.log('a',a);

						a.oWords = a.o.split(' ');

						// db.attendees.put(a);
						bulk.push(a);

						if (a.avatar) {
							// console.log('preloading', 'https://cdn.attendzen.io/' + $event.accountRef + '/thumb_' + a.avatar);
							preloadImage('https://cdn.attendzen.io/' + $event.accountRef + '/thumb_' + a.avatar);
						}

					}
				}

				db.attendees.bulkAdd(bulk);

				if (payload?.syncToken) {
					syncToken = payload.syncToken;
					if (syncClient) {
						syncClient.updateToken(syncToken);
					}
				}

				if (payload?.syncChannel) {
					syncChannel = payload.syncChannel;
				}

				if (payload?.vips) {
					$vips = payload.vips;
				}

				if (payload?.templateHashtags) {
					$templateHashtags = payload.templateHashtags;
				}

				if (payload?.templates) {
					$templates = payload.templates;
					// Preload resources needed for offline badge printing
					// (but only on desktops, as only desktops have access to the button)
					if (isDesktop()) {
						preloadCss('https://res.attendzen.io/css/core.css');
						preloadCss(`https://res.attendzen.io/css/${$event.theme.theme.slug}.css`);
						const cdnurl = 'https://cdn.attendzen.io';
						for (const [t, tx] of Object.entries($templates)) {
							if (!tx) continue;
							if (tx.bg) {
								for (const side of Object.keys(tx.bg)) {
									const bg = tx.bg[side];
									if (bg.type == 'image') {
										if (bg.image) {
											if (bg.image.filename) {
												if (bg.imagePresentation == 'tinted') {
													if (bg.image.format == 'svg') {
														preloadImage(`${cdnurl}/${$event.accountRef}/mask_${bg.image.filename.replace(/\.svg$/,'.png')}`);
													} else {
														preloadImage(`${cdnurl}/${$event.accountRef}/mask_${bg.image.filename.replace(/\.jpe?g$/,'.png')}`);
													}
												} else {
													if (bg.image.format == 'svg') {
														preloadImage(`${cdnurl}/${$event.accountRef}/${bg.image.filename}`);
													} else if (bg.image.type == 'logo') {
														preloadImage(`${cdnurl}/${$event.accountRef}/trim_${bg.image.filename}`);
													} else {
														preloadImage(`${cdnurl}/${$event.accountRef}/max_${bg.image.filename}`);
													}
												}
											} else if (bg.image.unsplash) {
												preloadImage(`${bg.image.unsplash.url}&w=1200`);
											}
										}
									}
								}
							}
							if (tx.components) {
								for (const side of Object.keys(tx.components)) {
									for (const c of tx.components[side]) {
										if (c.component == 'Image') {
											if (c.image) {
												if (c.image.filename) {
													if (c.presentation == 'tinted') {
														if (c.image.format == 'svg') {
															preloadImage(`${cdnurl}/${$event.accountRef}/mask_${c.image.filename.replace(/\.svg$/,'.png')}`);
														} else {
															preloadImage(`${cdnurl}/${$event.accountRef}/mask_${c.image.filename.replace(/\.jpe?g$/,'.png')}`);
														}
													} else {
														if (c.image.format == 'svg') {
															preloadImage(`${cdnurl}/${$event.accountRef}/${c.image.filename}`);
														} else if (c.image.type == 'logo') {
															preloadImage(`${cdnurl}/${$event.accountRef}/trim_${c.image.filename}`);
														} else {
															preloadImage(`${cdnurl}/${$event.accountRef}/max_${c.image.filename}`);
														}
													}
												} else if (c.image.unsplash) {
													preloadImage(`${c.image.unsplash.url}&w=1200`);
												}
											}
										} else if (c.component == 'SponsorsGrid') {
											for (const s of c.sponsors) {
												if (s.logo.filename) {
													if (c.presentation == 'tinted') {
														if (s.logo.format == 'svg') {
															preloadImage(`${cdnurl}/${$event.accountRef}/mask_${s.logo.filename.replace(/\.svg$/,'.png')}`);
														} else {
															preloadImage(`${cdnurl}/${$event.accountRef}/mask_${s.logo.filename.replace(/\.jpe?g$/,'.png')}`);
														}
													} else {
														if (s.logo.format == 'svg') {
															preloadImage(`${cdnurl}/${$event.accountRef}/${s.logo.filename}`);
														} else {
															preloadImage(`${cdnurl}/${$event.accountRef}/trim_${s.logo.filename}`);
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}

				if (payload?.splash) {
					$splash = payload.splash.splash;
					splashDt = payload.splash.dt;
					if ($splash.fgImage && $splash.fgImage.filename) {
						if ($splash.fgImage.format == 'svg') {
							preloadImage('https://cdn.attendzen.io/' + $event.accountRef + '/' + $splash.fgImage.filename);
						} else {
							preloadImage('https://cdn.attendzen.io/' + $event.accountRef + '/trim_' + $splash.fgImage.filename);
						}
						if ($splash.fgImagePresentation == 'tinted') {
							preloadImage('https://cdn.attendzen.io/' + $event.accountRef + '/mask_' + $splash.fgImage.filename.replace(/\.svg$/,'.png'));
						}
					}
				}

				if (payload?.crmFields) {
					$crmFields = payload.crmFields;
				}

				if ($attendee) {
					$attendee = await db.attendees.get($attendee.ref);
				}

				if (!inBackground) {
					$busy = false;
				}

				//refresher = setInterval(sync, 1000*60*10); // every 10 mins, if we're online

				setTimeout(() => {
					if ($appElement) {
						$appElement.classList.remove('fade');
					}
				}, 800);

			}

		}

		if ($event && $event.ends && !isYetToCome($event.ends)) {
			$modal = {
				message: $event.name + ' has now ended.',
				details: [],
				opts: [
					{
						name: "Lock device",
						f: () => {
							$showSettings = false;
							resetEvent();
						}
					},
				]
			};
		}

	}

	async function setRefresh() {
		if (mounted) {
			if ($offline) {
				await syncClient.shutdown();
				syncClient = null;
				clearInterval(refresher);
			} else {
				sync(true);
			}
		}
	}

	async function preloadImage(url) {
		let img = new Image();
		img.crossOrigin = "anonymous";
		img.src = url;
		// console.log("Preloaded img " + url);
	}

	async function preloadCss(url) {
		let ifrm = document.createElement("iframe");
		const html = `<head><link rel="stylesheet" crossorigin="anonymous" href="${url}"/></head><body></body>`;
		ifrm.srcdoc = html;
		document.body.appendChild(ifrm);
		setTimeout(() => {
			ifrm.remove();
		}, 400);
		// console.log("Preloaded css " + url);
	}

	async function twilioConnect() {
		if (!syncClient) {

			console.log('Connecting Twilio...');

			syncClient = new Twilio.Sync.Client(syncToken);

			syncClient.on('connectionError', (connectionError) => {
				console.error('Connection was interrupted:', connectionError.message);
				console.error('Is terminal:', connectionError.terminal);
				if (connectionError.terminal && !$offline) {
					sync(false);
				}
			});

			syncClient.on('tokenAboutToExpire', () => {
				// console.log('Sync token about to expire -- RENEWING');
				sync(true);
			});

			let syncStream = await syncClient.stream(syncChannel);
			syncStream.on('messagePublished', syncMessage);

		}
	}

	async function syncMessage(message) {
		let messageData = message.message.data;
		// console.log('messageData', messageData);
		if (messageData?.type == 'attendeeStatus') {
			// console.log('updating attendee status', messageData.attendeeRef, messageData.status);
			db.attendees.update(messageData.attendeeRef, {
				status: messageData.status
			});
			if ((messageData.status == 'checked-in') && $settings.staffed && (!messageData.staffed) && (messageData.deviceRef != $deviceRef) && isVIP(messageData.attendeeRef)) {
				// TODO: a better toast notification
				const a = await db.attendees.get(messageData.attendeeRef);
				if (a) {
					alert(a.f + ' ' + a.l + ' has checked in on device ' + messageData.deviceName);
				}
			}
		} else if (messageData?.type == 'attendeeUpdated') {
			// console.log('updating attendee', messageData.attendeeRef);
			syncAttendee(messageData.attendeeRef);
		} else if (messageData?.type == 'resync') {
			// console.log('resync');
			sync(true);
		} else if (messageData?.type == 'lock') {
			// console.log('lock', messageData.deviceRef);
			if (!messageData.deviceRef || (messageData.deviceRef == $deviceRef)) {
				$showSettings = false;
				resetEvent();
			}
		}
	}

	async function syncAttendee(attendeeRef) {

		// console.log('syncAttendee', attendeeRef);

		const attendeeData = await getServerData('/checkin/sync/attendee', {
			attendee: attendeeRef
		});

		if (attendeeData.attendee) {
			db.attendees.update(attendeeRef, attendeeData.attendee);
			if ($attendee && ($attendee.ref == attendeeRef)) {
				$attendee = attendeeData.attendee;
			}
		}

	}

	function setThemeVars() {
		if ($event.theme && $event.theme.checkin) {

			let vars = JSON.parse(JSON.stringify($event.theme.checkin));

			delete vars.logo;
			delete vars.logoSize;
			delete vars.logoStyle;

			const buttonTextColor = bestContrast(
				vars.accentColor,
				['bgColor', vars.bgColor],
				['textColor', vars.textColor]
			);

			vars.buttonTextColor = vars[buttonTextColor];

			vars.errorColor = generateErrorColor(vars.bgColor, vars);
			vars.successColor = generateSuccessColor(vars.bgColor, vars);

			const base = isPale(vars.bgColor) ? '#ffffff' : '#000000';
			const inverseBase = isPale(vars.bgColor) ? '#000000' : '#ffffff';
			const accentBase = isPale(vars.accentColor) ? '#ffffff' : '#000000';
			const errorBase = isPale(vars.errorColor) ? '#ffffff' : '#000000';
			const successBase = isPale(vars.successColor) ? '#ffffff' : '#000000';

			for (const b of [95,90,80,60,40,20,10,5]) {

				const amount = 100 - b;

				let blendKey = 'blend-' + String(b).padStart(2, '0');

				vars[blendKey] = colorMix(vars.bgColor, inverseBase, amount);

			}

			vars['accentBlend-120'] = colorMix(vars.accentColor, accentBase, 80);
			vars['errorBlend-120'] = colorMix(vars.errorColor, errorBase, 80);
			vars['successBlend-120'] = colorMix(vars.successColor, successBase, 80);
			vars['blend-x'] = colorMix(vars.bgColor, base, 60);

			vars['shadowColor'] = (getBrightness(vars.bgColor) > 30) ? '#00000011' : '#ffffff06';
			vars['darkShadowColor'] = (getBrightness(vars.bgColor) > 30) ? '#00000033' : '#ffffff11';

			themeVars = vars;

		}
	}

	function generateErrorColor(basis, vars) {
		if ($event && $event.theme && $event.theme.colors) {
			return getErrorColor([
				basis,
				$event.theme.colors['accent1'],
				$event.theme.colors['bgColor'],
				$event.theme.colors['textColor'],
				$event.theme.colors['accent2'],
				$event.theme.colors['accent3'],
				$event.theme.colors['accent4'],
				$event.theme.colors['accent5'],
				$event.theme.colors['accent6']
			]);
		} else {
			return getErrorColor([
				basis,
				vars['accentColor'],
				vars['bgColor'],
				vars['textColor']
			]);
		}
	}

	function generateSuccessColor(basis, vars) {
		if ($event && $event.theme && $event.theme.colors) {
			return getSuccessColor([
				basis,
				$event.theme.colors['accent1'],
				$event.theme.colors['bgColor'],
				$event.theme.colors['textColor'],
				$event.theme.colors['accent2'],
				$event.theme.colors['accent3'],
				$event.theme.colors['accent4'],
				$event.theme.colors['accent5'],
				$event.theme.colors['accent6']
			]);
		} else {
			return getSuccessColor([
				basis,
				vars['accentColor'],
				vars['bgColor'],
				vars['textColor']
			]);
		}
	}

	function setFont() {
		if ($settings.staffed) {
			font = "'Inter', sans-serif";
		} else {
			font = $event?.theme?.fonts?.text?.family;
		}
	}

	function setIsNarrowScreen() {
		$isNarrowScreen = window.matchMedia("screen and (max-width: 640px)").matches;
	}

	$: if (twilioLoaded && syncToken) {
		twilioConnect();
	}

	$: if ($unlockCode) {
		sync(false);
	} else {
		resetEvent();
	}

	$: setRefresh($offline);

	$: if ($event) {
		setThemeVars();
		setFont();
	}

	$: setFont($settings.staffed);

	$: if ($deviceRef) {
		Sentry.setUser($deviceRef);
	}

	// $: console.log('showSettings', $showSettings);
	// $: console.log('busy', $busy);

</script>

<style>
	:global(html) {
  		margin: 0;
  		overscroll-behavior-y: none;
	}
	:global(iframe) {
		position: absolute;
		top: 0;
		left: 0;
		width: 0;
		height: 0;
		visibility: hidden;
	}
	main {
		text-align: center;
		box-sizing: border-box;
		margin: 0 auto;
		width: 100%;
		background: var(--bgColor);
		transition: background-color 0.2s ease;
		transition-delay: 0.2s;
		position: relative;
		padding: 0 6%;
/*		padding-top: env(safe-area-inset-top);*/
	}
	main > div {
/*		height: calc(100dvh - env(safe-area-inset-top));*/
		height: 100dvh;
		min-height: 650px;
		margin: 0 auto;
		width: 100%;
		max-width: 1200px;
		box-sizing: border-box;
		position: relative;
		transition: opacity 0.4s ease;
	}
	:global(main.fade) > div.inner {
		opacity: 0;
	}
	main > div > div {
		position: absolute;
		inset: 0;
/*		padding-top: 6rem;*/
		padding-top: max(6rem, calc(4.3rem + env(safe-area-inset-top)));
		font-family: var(--font, 'Inter');
		transition: padding-top 0.4s ease;
	}
	main.logo-medium > div > div {
/*		padding-top: 7rem;*/
		padding-top: max(7rem, calc(5.3rem + env(safe-area-inset-top)));
	}
	main.logo-large > div > div {
/*		padding-top: 8rem;*/
		padding-top: max(8rem, calc(6.3rem + env(safe-area-inset-top)));
	}
	main > div > div.unbranded {
/*		padding-top: 1.8rem !important;*/
		padding-top: max(1.8rem, calc(0.5rem + env(safe-area-inset-top))) !important;
	}
	main > div > div :global(*) {
		font-family: inherit;
	}
	svg.azn {
		position: absolute;
		width: 80%;
		left: 50%;
		top: calc(50% - 4rem);
		transform: translate(-50%,-50%);
		max-width: 240px;
	}
	svg.azn path {
		fill: var(--accentColor);
	}
	.go {
		position: absolute;
		width: 100%;
		top: auto;
		bottom: 5rem;
		display: none;
		padding: 0;
		max-width: 240px;
		left: 50%;
		transform: translateX(-50%);
	}
	.go.visible {
		display: block;
	}
	#busy {
		position: fixed;
		inset: 0;
		z-index: 30000;
		display: grid;
		place-content: center;
		padding: 0;
	}
	#busy:before {
		content: '';
		position: fixed;
		inset: 0;
		background: var(--blend-x, var(--bgColor));
		opacity: 0.9;
		pointer-events: none;
	}
</style>

<svelte:window on:resize={setIsNarrowScreen}/>

<svelte:head>
	{#if $event?.theme?.fonts?.import}
		<link rel="stylesheet" href={$event.theme.fonts.import}/>
	{/if}
  	<link rel="stylesheet" href="https://www.attendzen.io/res/display/default/fonts/Inter/inter.css"/>
  	<link rel="stylesheet" href="https://res.attendzen.io/css/textcontent.css"/>
	{#if $event?.theme?.theme?.slug}
		<link rel="stylesheet" href="https://res.attendzen.io/css/{$event.theme.theme.slug}-textcontent.css"/>
	{/if}
  	<script type="text/javascript" src="https://media.twiliocdn.com/sdk/js/sync/releases/3.0.1/twilio-sync.min.js" on:load={() => { twilioLoaded = true }}></script>
	{#key $event.token}
		<script src="https://register.attendzen.io/build/bundle.js"></script>
	{/key}
</svelte:head>

{#if mounted}
	<main bind:this={$appElement} use:cssVars={themeVars} class="logo-{$logoSize}">
		<div class="inner">
			{#if $unlockCode}
				<div style="--font:{font};" class:unbranded={$unbranded}>
					{#if $event && $event.ref}
						{#key $event.ref}
							<Branding />
						{/key}
					{/if}
					{#if $message}
						<Message/>
					{:else if $showRegform}
						<Register/>
					{:else if $attendee}
						<Attendee/>
					{:else}
						{#if $screen == 'qr'}
							<QR/>
						{:else if ($screen == 'search') || ($settings && $settings.staffed)}
							<Search/>
						{:else}
							{#key splashDt}
								<Splash/>
							{/key}
						{/if}
					{/if}
				</div>
			{:else}
				<svg class="azn" viewBox="0 0 1024 1024"><path d="M1024 272.6h-45.4v-227.2h-227.2v-45.4h272.6zM1024 1024h-272.6v-45.4h227.2v-227.2h45.4zM709.1 539.6v120.8h-394.2v-85.8l210.6-292.4h-200.6v-120.8h374.2v85.8l-211.5 292.4h221.5zM512 885.3c-69 0-162.6-22.5-227.4-54.6l-1.2-.6 45.9-92.3 1.2.6c55.7 27.6 116.7 41.6 181.5 41.6 64.7 0 125.8-14 181.5-41.6l1.2-.6 45.9 92.3-1.2.6c-64.8 32.2-158.4 54.6-227.4 54.6zM45.4 272.6h-45.4v-272.6h272.6v45.4h-227.2zM272.6 1024h-272.6v-272.6h45.4v227.2h227.2z"/></svg>
				<div class="go" class:visible={!showInstallMessage}>
					<Button label="Unlock" wide={true} on:click={() => { $showSettings = true }}>
						<svg viewBox="0 0 80 80"><path d="M44 46c0-2.2-1.8-4-4-4s-4 1.8-4 4c0 1.5.8 2.8 2 3.4v5.6c0 1.1.9 2 2 2s2-.9 2-2v-5.5c1.2-.7 2-2 2-3.5zM57 34h-3v-5c0-7.7-6.3-14-14-14-6.4 0-11.7 4.3-13.4 10.1l3.7 1.7c1-4.5 5-7.8 9.7-7.8 5.5 0 10 4.5 10 10v5h-27c-2.2 0-4 1.8-4 4v23c0 2.2 1.8 4 4 4h34c2.2 0 4-1.8 4-4v-23c0-2.2-1.8-4-4-4zm0 25c0 1.1-.9 2-2 2h-30c-1.1 0-2-.9-2-2v-19c0-1.1.9-2 2-2h30c1.1 0 2 .9 2 2v19z"/></svg>
					</Button>
				</div>
			{/if}

			{#if $showSettings}
				<Settings on:reset={resetEvent}/>
			{/if}

			<InstallMessage bind:showInstallMessage/>

		</div>

		{#if $bigError}
			<div id="busy" class="modal" in:fade|local={{ duration:200 }} out:fade|local={{ duration:200 }}>
				<BigErrorModal {mounted}/>
			</div>
		{:else if $modal}
			<div id="busy" class="modal" in:fade|local={{ duration:200 }} out:fade|local={{ duration:200 }}>
				<Modal/>
			</div>
		{:else if $busy}
			<div id="busy" in:fade|local={{ delay:600, duration:300 }} out:fade|local={{ duration:300 }}>
				<Spinner size="100" speed="1200" thickness="1" gap="30"/>
			</div>
		{/if}

	</main>

{:else}

	{#if $bigError}
		<div id="busy" class="modal" in:fade|local={{ duration:200 }} out:fade|local={{ duration:200 }}>
			<BigErrorModal {mounted}/>
		</div>
	{/if}

{/if}
