<script>

	import { fly } from "svelte/transition";

	export let type = 'info';
	export let bounce = false;

</script>

<style>

	.note {
		background: var(--blend-x);
		border: 1px solid var(--blend-05);
		border-radius: 4px;
		box-shadow: 0 0.4rem 0.8rem var(--darkShadowColor);
		display: flex;
/*		align-items: center;*/
		gap: 1rem;
		padding: 0.8rem 1rem;
		color: var(--textColor);
		box-sizing: border-box;
		width: 600px;
		max-width: 100%;
		margin: 0 auto;
		text-align: left;
	}

	:global(.note) + .note {
		margin-top: 1rem;
	}

	span.icon {
		display: block;
		line-height: 0;
		flex: 0 0 1.8rem;
		text-align: center;
	}

	.note.info span.icon {
		color: var(--successColor);
	}

	.note.vip span.icon {
		color: var(--accentColor);
	}

	.note.alert span.icon {
		color: var(--errorColor);
	}

	/*.note.alert span.icon .mark {
		fill: var(--textColor);
	}*/

	.note svg {
		width: 1.4rem;
	}

	.note > div {
		line-height: 1.4;
	}

	@keyframes bounce {
		70%  { transform: translateY(0%);   }
		80%  { transform: translateY(-20%); }
		90%  { transform: translateY(0%);   }
		95%  { transform: translateY(-16%);  }
		97%  { transform: translateY(0%);   }
		99%  { transform: translateY(-8%);  }
		100% { transform: translateY(0);    }
	}

	.note.bounce {
		animation: bounce 2s ease;
		animation-delay: 0.6s;
	}

</style>

<div class="note {type}" class:bounce in:fly={{ duration: 200, y: 20, delay: 200 }}>
	<span class="icon">
		{#if type == 'alert'}
			<svg viewBox="0 0 56 56"><path class="st0" d="M56 28c0 15.46-12.54 28-28 28s-28-12.54-28-28 12.54-28 28-28 28 12.54 28 28zm-28-24c-13.25 0-24 10.75-24 24s10.75 24 24 24 24-10.75 24-24-10.75-24-24-24z"/><path d="M28 40.59c1.48 0 2.7 1.22 2.7 2.7s-1.22 2.71-2.7 2.71-2.7-1.22-2.7-2.7 1.22-2.71 2.7-2.71zm-2.33-28.1c0-1.54.9-2.49 2.33-2.49s2.33.95 2.33 2.49v21.61c0 1.54-.9 2.49-2.33 2.49s-2.33-.95-2.33-2.49v-21.61z"/></svg>
		{:else if type == 'vip'}
			<svg viewBox="0 0 47.4 45.1"><path d="M44.6 17.9m-20.9-17.9c-1.2 0-2.2.7-2.6 1.9l-4.3 13.3h-14c-1.2 0-2.2.7-2.6 1.9-.4 1.1 0 2.3 1 3l11.3 8.2-4.3 13.3c-.4 1.1 0 2.3 1 3 .5.3 1 .5 1.6.5s1.1-.2 1.6-.5l11.3-8.2 11.3 8.2c.5.3 1 .5 1.6.5s1.1-.2 1.6-.5c.9-.7 1.3-1.9 1-3l-4.3-13.3 11.3-8.2c.7-.5 1.2-1.3 1.2-2.2 0-1.5-1.2-2.7-2.7-2.7h-14l-4.3-13.3c-.5-1.2-1.5-1.9-2.7-1.9z"/></svg>
		{:else}
			<svg viewBox="0 0 60 60"><path d="M30 0c16.5 0 30 13.5 30 30s-13.5 30-30 30-30-13.5-30-30 13.5-30 30-30zm0 56c14.3 0 26-11.7 26-26s-11.7-26-26-26-26 11.7-26 26 11.7 26 26 26zM25.3 43.2l-12.2-12.2 4.3-4.2 7.9 7.9 17.2-17.2 4.3 4.3z"/></svg>
		{/if}
	</span>
	<div>
		<slot/>
	</div>
</div>