<script>

	import { onMount } from 'svelte';
	import { fade } from 'svelte/transition';
	import { quadIn, cubicInOut } from 'svelte/easing';

	export let size = 12;

	let mounted = false;

	function drawWithLineCap(node, {
		delay = 0,
		speed,
		duration,
		easing = cubicInOut
	}) {
		const hasEndCaps = getComputedStyle(node).strokeLinecap !== 'butt'
		let capSize = hasEndCaps ? parseInt(getComputedStyle(node).strokeWidth) : 0
		const len = node.getTotalLength() + capSize;

		if (duration === undefined) {
			if (speed === undefined) {
				duration = 800;
			} else {
				duration = len / speed;
			}
		} else if (typeof duration === 'function') {
			duration = duration(len);
		}

		return {
			delay,
			duration,
			easing,
			css: (t, u) => `stroke-dasharray: ${t * len} ${u * len}`
		};
	}

	onMount(() => {
		mounted = true;
	});

</script>

<style>
	div {
		text-align: center;
		padding: 1rem 0;
	}
	svg {
		width: var(--size);
		height: var(--size);
	}
	path {
		stroke: var(--successColor, var(--accentColor, #cf0));
		fill: transparent;
		stroke-width: 2;
	}
</style>

{#if mounted}
	<div in:fade={{ delay: 600, duration: 200 }} style="--size: {size}rem;">
		<svg viewBox="0 0 80 80">
			<path in:drawWithLineCap={{ delay: 600, duration: 400 }} d="M61.9,9C55.7,4.6,48.2,2,40,2C19,2,2,19,2,40s17,38,38,38s38-17,38-38c0-4-0.6-7.8-1.7-11.4"/>
			<path in:drawWithLineCap={{ delay: 600, duration: 500, easing: quadIn }} d="M22.4,43.5L33,54.1c0.2,0.2,0.5,0.2,0.8,0c1-1,44.3-44.3,44.3-44.3"/>
		</svg>
	</div>
{/if}