<script>

	import { onMount } from 'svelte';

	import Field from '../ui/Field.svelte';
	import ButtonGroup from '../ui/ButtonGroup.svelte';
	import Button from '../ui/Button.svelte';

	import { attendee, event, bigError, busy, settings, offline, templateHashtags, countryOpts, crmFields } from '../lib/stores.js';
	import { postServerData } from '../lib/prelude.js';
	import { db } from "../lib/db.js";
	import { showSuccess } from "../lib/success.js";

	export let active = true;

	let workingData = {};
	let errs = {};
	let dataOK = true;
	let mounted = false;

	onMount(() => {
		if ($attendee.badgeTemplate && $templateHashtags && $templateHashtags[$attendee.badgeTemplate]) {
			for (const f of $templateHashtags[$attendee.badgeTemplate]) {
				if (f.component) {
					// console.log('component', f.component);
					if (f.component.hook && ((f.component.hook == 'f') || (f.component.hook == 'l') || (f.component.hook == 'o'))) {
						workingData[f.id] = $attendee[f.component.hook];
					} else {
						workingData[f.id] = $attendee[f.id];
					}
				}
			}
		}
		mounted = true;
	});

	function checkData() {
		let ok = true;
		for (const e of Object.keys(errs)) {
			if (errs[e]) {
				ok = false;
			}
		}
		dataOK = ok;
	}

	async function saveAndClose() {

		$busy = true;

		if ($offline) {

			const dt = new Date().toISOString().replace('Z',''); // UTC

			const json = JSON.stringify(workingData);

			db.offline.add({
				action: 'editBadge',
				event: $event.ref,
				attendee: $attendee.ref,
				updates: json,
				dt: dt
			});

			let updates = {};

			if ($attendee.badgeTemplate) {
				for (const f of $templateHashtags[$attendee.badgeTemplate]) {
					if (f.component) {
						if (f.component.hook && ((f.component.hook == 'f') || (f.component.hook == 'l') || (f.component.hook == 'o'))) {
							updates[f.component.hook] = workingData[f.id];
						} else {
							updates[f.id] = workingData[f.id];
						}
					}
				}
			}

			await db.attendees.update($attendee.ref, updates);

			$attendee = await db.attendees.get($attendee.ref);

			showSuccess(function(){
				$busy = false;
				active = false;
			});

		} else {

			console.log('saving', workingData);

			const json = JSON.stringify(workingData);

			const attendeeResponse = await postServerData('/checkin/sync/attendee', {
				attendee: $attendee.ref,
				json: json
			});

			if (!$bigError) {
				showSuccess(function(){
					$busy = false;
					active = false;
				});
			}

		}

	}

	function getOptions(f) {
		if (f.component?.component == 'Country') {
			return $countryOpts;
		} else if (f.component?.options) {
			return f.component?.options;
		} else if (f.component?.crm && $crmFields) {
			for (const c of $crmFields) {
				if (c.ref == f.component.crm) {
					return c.options;
				}
			}
		} else {
			return null;
		}
	}

	$: if (errs) {
		checkData();
	}

</script>

<style>
	.wrapper {
		position: absolute;
		inset: 0;
		display: flex;
		flex-direction: column;
	}
	form {
		padding: 1rem 2rem;
		flex: 1 1 auto;
		overflow-y: auto;
		background:
			linear-gradient(var(--blend-x) 30%, transparent),
			linear-gradient(transparent, var(--blend-x) 70%) 0 100%,
			linear-gradient(transparent, var(--shadowColor)),
			linear-gradient(var(--shadowColor), transparent) 0 100%;
		background:
			linear-gradient(var(--blend-x) 30%, transparent),
			linear-gradient(transparent, var(--blend-x) 70%) 0 100%,
			linear-gradient(var(--shadowColor), transparent),
			linear-gradient(transparent, var(--shadowColor)) 0 100%;
		background-repeat: no-repeat;
		background-color: var(--blend-x);
		background-size: 100% 80px, 100% 80px, 100% 4rem, 100% 4rem;
		background-attachment: local, local, scroll, scroll;
	}
	.wrapper :global(div.buttonGroup) {
		padding-left: 2rem;
		padding-right: 2rem;
		padding-bottom: 1.4rem;
	}
</style>

{#if mounted}
	<div class="wrapper">
		<form id="badgeForm" on:submit|preventDefault={saveAndClose}>
			{#if $attendee.badgeTemplate && $templateHashtags && $templateHashtags[$attendee.badgeTemplate]}
				{#each $templateHashtags[$attendee.badgeTemplate] as f }
					{#if f.component && !(f.component.hook == 'e')}
						<Field
							ref={'f_'+f.id}
							bind:value={workingData[f.id]}
							component={f.component?.component ? f.component?.component : 'TextField'}
							label={f.value}
							bind:err={errs[f.id]}
							options={getOptions(f)}
						/>
					{/if}
				{/each}
			{/if}
		</form>

		<ButtonGroup wide={false} center={false}>
			<Button type="submit" form="badgeForm" label="Save changes" double={true} green={true} disabled={!dataOK}>
				<svg viewBox="0 0 80 80"><path d="M28.8 59.6l-12.6-12.6 4.3-4.2 8.3 8.3 30.7-30.7 4.3 4.2z"/></svg>
			</Button>
		</ButtonGroup>
	</div>
{/if}