<script>

	import { onMount } from 'svelte';
	import { fly } from 'svelte/transition';
	import { bounceOut } from "svelte/easing";

	import { isIOS, isIpadOS, isInStandaloneMode } from "./lib/device.js";

	export let showInstallMessage = false;

	onMount(() => {
		if (isIOS() && !isInStandaloneMode()) {
			showInstallMessage = true;
		}
		// showInstallMessage = true;
	});

</script>

<style>
	#installMessage {
		position: fixed;
		overflow: visible;
		left: 50%;
		width: calc(100% - 1rem);
		max-width: 400px;
		bottom: 1em;
		background: var(--textColor);
		color: var(--bgColor);
		font-size: 1rem;
		padding: 0.4em;
		border-radius: 6px;
		transform: translateX(-50%);
		min-height: 8rem;
		display: grid;
		place-content: center;
	}
	#installMessage.phone:after {
		content: '';
		position: absolute;
		bottom: -5px;
		left: calc(50% - 5px);
		width: 10px;
		height: 10px;
		transform: rotate(45deg);
		background: var(--textColor);
	}	
	#installMessage svg {
		vertical-align: middle;
		width: 2em;
		position: relative;
		top: -0.2em;
	}
	#installMessage path {
		/*fill: #007AFF;*/
		fill: #30AAFF;
	}
</style>

{#if showInstallMessage}
	<div id="installMessage" class:phone={!isIpadOS} transition:fly={{ y: -80, duration: 800, delay: 600, easing: bounceOut }}>
		<p>
			<span><strong>Install on your device:</strong><br/>tap</span>
			<svg viewBox="0 0 50 50"><path d="M30.3 13.7L25 8.4l-5.3 5.3-1.4-1.4L25 5.6l6.7 6.7z"/><path d="M24 7h2v21h-2z"/><path d="M35 40H15c-1.7 0-3-1.3-3-3V19c0-1.7 1.3-3 3-3h7v2h-7c-.6 0-1 .4-1 1v18c0 .6.4 1 1 1h20c.6 0 1-.4 1-1V19c0-.6-.4-1-1-1h-7v-2h7c1.7 0 3 1.3 3 3v18c0 1.7-1.3 3-3 3z"/></svg>
			<span>and then tap Add to Home Screen</span>
		</p>
	</div>
{/if}