<script>

  // Based on https://github.com/stephane-vanraes/svelte-progresscircle/blob/master/src/index.svelte

  import { v4 as uuid } from 'uuid';

  export let value = 0;
  export let max = 100;
  export let micro = false;

  let id = uuid();

  $: progressPath = () => {
    if (isNaN(value) || (value <= 0)) {
      return "";
    } else if (value >= max) {
      return "M50,5A45 45 0 1 1 49.9999 5";
    } else {
      const angle = Math.PI * 2 * (value / max);
      const x = 50 + Math.cos(angle - Math.PI / 2) * 45;
      const y = 50 + Math.sin(angle - Math.PI / 2) * 45;

      let path = "M50,5";

      if (angle > Math.PI) {
        path += "A45 45 0 0 1 50 95";
      }

      path += `A45 45 0 0 1 ${x} ${y}`;

      return path;
    }
  };

</script>

<style>
  svg {
    height: 100%;
    position: absolute;
    width: 100%;
    overflow: visible;
  }
  path.track {
    stroke: var(--bgColor);
    stroke-width: 10%;
    fill: transparent;
  }
  @media (max-width: 640px) {
    path.track {
      stroke: var(--blend-10);
    }
  }
  path.progress {
    stroke: var(--accentColor);
    stroke-width: 10%;
    marker-end: var(--mark);
    fill: transparent;
  }
  path.mask {
    stroke: var(--bgColor);
    stroke-width: 10%;
    transform: rotate(270deg);
    transform-origin: 50% 50%;
    fill: transparent;
  }
  circle {
    fill: var(--accentColor);
  }

  .micro path.track {
    stroke: var(--buttonTextColor);
    opacity: 0.4;
    stroke-width: 20%;
  }
  .micro path.progress {
    stroke: var(--buttonTextColor);
    stroke-width: 20%;
  }
  .micro path.mask {
    display: none;
  }
  .micro circle {
    fill: var(--buttonTextColor);
  }

  div {
    height: 100%;
    position: relative;
    width: 100%;
  }
  /*span {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }*/
</style>

<div class:micro style="--mark:url('#m-{id}')">
  <svg viewBox="0 0 100 100">
    <defs>
      <marker id="m-{id}" viewBox="-1 -1 2 2" markerWidth="1" orient="auto">
        <circle r="1"/>
      </marker>
    </defs>
    <path class="track" d="M50,5A45 45 0 1 1 49.9999 5" />
    <path class="progress" d="{progressPath()}" />
    {#if value < 25}
      <path class="mask" d="M50,5A45 45 0 0 1 95 50"/>
    {/if}
  </svg>
  <div>
    <slot/>
  </div>
</div>