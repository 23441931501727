<script>

	import { onMount, createEventDispatcher } from 'svelte';
	import { fly } from 'svelte/transition';

	// import cssVars from 'svelte-css-vars';

	import Button from './Button.svelte';

	import { appElement } from '../lib/stores.js';

	const dispatch = createEventDispatcher();

	export let closeButton = true;
	export let title = '';
	export let short = false;
	export let pullup = false;

	// let vars = {};

	// onMount(() => {
	// 	vars.textColor = getComputedStyle($appElement).getPropertyValue('--bgColor');
	// 	vars.bgColor = getComputedStyle($appElement).getPropertyValue('--textColor');
	// });

	function handleKeydown(ev) {
		const key = ev.key;
		if (key === "Escape") {
			if (closeButton) {
				dispatch('close');
			}
		}
	}

</script>

<style>
	.panel {
		background: var(--blend-x);
		color: var(--textColor);
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		box-shadow: 0 1rem 2rem var(--darkShadowColor);
		border: 1px solid var(--blend-10);
		border-bottom: none;
		position: fixed;
		z-index: 20000;
		bottom: 0;
/*		height: calc(100dvh - env(safe-area-inset-top) - 6rem + 10px);*/
		height: calc(100dvh - max(6rem, calc(4.3rem + env(safe-area-inset-top))) + 10px);
		left: 50%;
		/*width: calc(100% - 1.4rem);
		max-width: 1090px;*/
		width: calc(95vw - 1.4rem);
		max-width: 1200px;
		transform: translateX(-50%);
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
		overflow: hidden;
	}
	.panel.short {
		height: unset;
/*		max-height: calc(100dvh - env(safe-area-inset-top) - 6rem + 10px);*/
		max-height: calc(100dvh - max(6rem, calc(4.3rem + env(safe-area-inset-top))) + 10px);
		padding-bottom: 1.6rem;
	}
	@media (max-width: 600px) {
		.panel {
			width: calc(100vw - 1.4rem);
		}
	}
	:global(.logo-medium) .panel {
/*		height: calc(100dvh - env(safe-area-inset-top) - 7rem + 10px);		*/
		height: calc(100dvh - max(7rem, calc(5.3rem + env(safe-area-inset-top))) + 10px);
	}
	:global(.logo-large) .panel {
/*		height: calc(100dvh - env(safe-area-inset-top) - 8rem + 10px);		*/
		height: calc(100dvh - max(8rem, calc(6.3rem + env(safe-area-inset-top))) + 10px);
	}
	:global(.unbranded) + .panel {
/*		height: calc(100dvh - env(safe-area-inset-top) - 1.8rem + 10px);		*/
		height: calc(100dvh - max(1.8rem, calc(1.8rem + env(safe-area-inset-top))) + 10px);
	}
	.header {
		flex: 0 0 auto;
		min-height: 2.4rem;
		text-align: left;
		padding: 0.8rem 2rem;
	}
	.header.hasTitle {
		background: var(--blend-x);
/*		border-bottom: 1px solid var(--blend-10);*/
	}
	.header :global(button) {
		position: absolute;
		top: 1rem;
		right: 1rem;
		z-index: 1000;
	}
	h2 {
		margin: 0.5rem 0;
		line-height: 1.3;
		color: var(--textColor);
		padding-right: 3rem;
	}
	.main {
		flex: 1 1 auto;
		overflow-y: auto;
		padding: 1rem 2rem;
		text-align: left;
		position: relative;
		background:
			linear-gradient(var(--blend-x) 30%, transparent),
			linear-gradient(transparent, var(--blend-x) 70%) 0 100%,
			linear-gradient(var(--shadowColor), transparent),
			linear-gradient(transparent, var(--shadowColor)) 0 100%;
		background-repeat: no-repeat;
		background-color: var(--blend-x);
		background-size: 100% 40px, 100% 40px, 100% 1rem, 100% 1rem;
		background-attachment: local, local, scroll, scroll;
	}
	.main.pullup {
		background: transparent;
		margin-top: -3rem;
	}
</style>

<svelte:body on:keydown={handleKeydown}/>

<div class="panel" class:short in:fly={{ y: 400, duration: 200 }}>
	{#if closeButton || title}
		<div class="header" class:hasTitle={title}>
			{#if closeButton}
				<Button on:click={() => { dispatch('close') }}>
					<svg viewBox="0 0 80 80">
						<title>Close</title>
						<path d="M40.06 44.53l-11 11-4.5-4.49 11-11-11-11 4.5-4.5 11 11 11-11 4.5 4.49-11 11 11 11-4.5 4.49-11-10.99z"/>
					</svg>
				</Button>
			{/if}
			{#if title}
				<h2>{title}</h2>
			{/if}
		</div>
	{/if}
	<div class="main" class:pullup>
		<slot/>
	</div>
	{#if $$slots.actions}
		<slot name="actions"/>
	{/if}
</div>
