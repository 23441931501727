<script>

	export let number = 0;
	export let locale = "en";

	$: formattedNumber = number.toLocaleString(locale);

</script>

<style>
	span {
		font-variant-numeric: tabular-nums;
	}
</style>

<span>{formattedNumber}</span>