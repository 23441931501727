export const getUA = () => {
    let device = "Device";
    if (isIpadOS()) {
        return 'iPad';
    }
    const ua = {
        "Linux": /Linux/i,
        "Android": /Android/i,
        "Chromebook": /CrOS/i,
        "iPad": /iPad/i,
        "iPhone": /iPhone/i,
        "Mac": /Macintosh/i,
        "Windows": /IEMobile|Windows/i,
    }
    Object.keys(ua).map(v => navigator.userAgent.match(ua[v]) && (device = v));
    return device;
}

export const isIOS = () => {
    if (/iPad|iPhone|iPod/.test(navigator.platform)) {
        return true;
    } else {
        return isIpadOS();
    }
}

export const isIpadOS = () => {
    return navigator.maxTouchPoints && navigator.maxTouchPoints > 2 && /MacIntel/.test(navigator.platform);
}

export const isInStandaloneMode = () => {
    return ('standalone' in window.navigator) && (window.navigator.standalone);
}

export const isTouchDevice = () => {
    return window.matchMedia("(pointer: coarse)").matches;
}

export const isDesktop = () => {
    const device = getUA();
    return ((device == 'Windows') || (device == 'Mac') || (device == 'Chromebook') || (device == 'Linux')) ? true : false;
}

// export const isNarrowScreen = () => {
//     return window.matchMedia("screen and (max-width: 640px)").matches;
// }
