import { busy, success } from './stores.js';

export const showSuccess = (callback) => {
	setTimeout(() => {
		success.set(true);
		setTimeout(() => {
			if (callback) callback();
			busy.set(false);
			setTimeout(() => {
				success.set(false);
			}, 600);
		}, 600);
	}, 600);
}