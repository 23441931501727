<script>

	import { onMount, onDestroy } from 'svelte';
	import { fade, fly } from 'svelte/transition';

	import { IdleSessionTimeout } from "idle-session-timeout";

	import ButtonGroup from '../ui/ButtonGroup.svelte';
	import Button from '../ui/Button.svelte';
	import BigTick from '../ui/BigTick.svelte';
	import Note from '../ui/Note.svelte';

	import { screen, attendee, settings } from '../lib/stores.js';
	import { isVIP } from "../lib/vips.js";

	// export let already;

	let session = new IdleSessionTimeout(30 * 1000); // 30s

	let vip = isVIP($attendee.ref);

	onMount(() => {
		session.start();
	});

	onDestroy(() => {
		session.dispose();
	});

	function clear() {
		$attendee = null;
		$screen = null;
	}
 
	session.onTimeOut = clear;

</script>

<style>
	.message {
		display: flex;
		flex-direction: column;
		height: 100%;
		box-sizing: border-box;
		padding-bottom: 2rem;
	}
	.display {
		max-width: 600px;
		margin: 0 auto;
		flex: 1 1 auto;
		overflow-y: auto;
		display: grid;
		place-content: center;
		padding-bottom: 2rem;
	}
	.success {
		margin-top: 2rem;
		/*font-size: 3rem;*/
		font-size: clamp(1.8rem, 3rem, 10vw);
		color: var(--textColor);
		font-weight: 700;
	}
	.success + p {
		margin-top: 1.4rem;
		/*font-size: 1.6rem;*/
		font-size: clamp(1rem, 1.6rem, 5vw);
		color: var(--textColor);
	}
</style>

<div class="message" in:fly={{ duration: 200, y: 10, delay: 100 }}>

	{#if vip && vip.attendeeMessage}
		<Note type="vip" bounce={true}>
			{vip.attendeeMessage}
		</Note>
	{/if}

	<div class="display">
		<div>
			<BigTick/>
			<p class="success">{$attendee.f}, you’re checked-in.</p>
			{#if $settings.badgeCollection}
				<p>{$settings.badgeCollection}</p>
			{/if}
		</div>
	</div>

	<div in:fade={{ duration: 400, delay: 1200 }}>
		<ButtonGroup wide={false}>
			<Button double={true} on:click={clear} label="OK"/>
		</ButtonGroup>
	</div>

</div>