<script>

	import { onMount } from 'svelte';

	import cssVars from 'svelte-css-vars';

	import { event } from '../../lib/stores.js';

	export let c;

	let cVars = {};
	let mounted = false;

	onMount(() => {
		cVars.bgColor = c.bgColor ? $event.theme.colors[c.bgColor] : 'transparent';
		cVars.color = c.color ? $event.theme.colors[c.color] : $event.theme.colors['textColor'];
		cVars.color2 = c.color2 ? $event.theme.colors[c.color2] : $event.theme.colors['textColor'];
		if ((c.presentation == 'gap') || (c.presentation == 'pair')) {
			cVars.gapSize = c.gapSize ? c.gapSize : 20;
		}
		if (c.presentation == 'pair') {
			cVars.ratio = c.ratio ? c.ratio : 50;
		}
		mounted = true;
	});

</script>

{#if mounted}
	<section class="component { c.component } { c.presentation }" use:cssVars={cVars}>
		<div></div>
	</section>
{/if}