<script>

	import { onMount, onDestroy } from 'svelte';

	import Background from './BadgeTemplate/Background.svelte';
	import AttendeePhoto from './BadgeTemplate/AttendeePhoto.svelte';
	import Divider from './BadgeTemplate/Divider.svelte';
	import Image from './BadgeTemplate/Image.svelte';
	import Pair from './BadgeTemplate/Pair.svelte';
	import QRCode from './BadgeTemplate/QRCode.svelte';
	import SponsorsGrid from './BadgeTemplate/SponsorsGrid.svelte';
	import TextBlock from './BadgeTemplate/TextBlock.svelte';

	import {
		event,
		attendee,
		templates
	} from '../lib/stores.js';

	export let w;

	const componentLookup = {
		'AttendeePhoto': AttendeePhoto,
		'Divider': Divider,
		'Image': Image,
		'Pair': Pair,
		'QRCode': QRCode,
		'SponsorsGrid': SponsorsGrid,
		'TextBlock': TextBlock,
	};

	let template;
	let mounted = false;

	onMount(() => {

		console.log('mounting BadgeTemplate', $attendee.ref);

		w.document.title = 'Badge';

		const templateRef = $attendee.badgeTemplate;
		template = $templates[templateRef];

		if ($event.theme?.fonts?.import) {
			const fcss = $event.theme.fonts.import;
			w.document.head.insertAdjacentHTML("beforeend", `<link crossorigin="anonymous" rel="stylesheet" href="${fcss}"/>`);
		}

		w.document.head.insertAdjacentHTML("beforeend", `<link crossorigin="anonymous" rel="stylesheet" href="https://res.attendzen.io/css/core.css"/>`);

		if ($event.theme?.theme?.slug) {
			const themeSlug = $event.theme.theme.slug;
			w.document.head.insertAdjacentHTML("beforeend", `<link crossorigin="anonymous" rel="stylesheet" href="https://res.attendzen.io/css/${themeSlug}.css"/>`);
			w.document.body.classList.add(themeSlug);
		}

		if ($event.theme?.fonts?.text?.name) {
			const textName = $event.theme.fonts.text.name.replace(/\s+/g, '-').toLowerCase();
			w.document.body.classList.add(`text-${textName}`);
		}

		if ($event.theme?.fonts?.headings?.name) {
			const headingsName = $event.theme.fonts.headings.name.replace(/\s+/g, '-').toLowerCase();
			w.document.body.classList.add(`headings-${headingsName}`);
		}

		if (template.stock?.folded && template.stock?.foldEdge) {
			const foldEdge = template.stock.foldEdge;
			w.document.body.classList.add(`fold-${foldEdge}`);
		}

		w.document.head.insertAdjacentHTML("beforeend", template.css);

		w.document.body.id = "pdf";

		mounted = true;

	});

</script>

{#if mounted}
	<div class="page">
		<div>
			<div class="badge" class:rotated={!template.stock?.folded && template.stock?.allowRotation && template.rotated}>
				<div class="bleed {template.bleeds[0].bottom} {template.bleeds[0].left} {template.bleeds[0].top} {template.bleeds[0].right}">
					<div class="side">
						<Background bg={template.bg.front}/>
						<div class="components">
							{#each template.components.front as c}
								<svelte:component this={componentLookup[c.component]} {c} />
							{/each}
						</div>
					</div>
					{#if template.stock.folded}
						<div class="reverse side">
							<Background bg={template.bg.back}/>
							<div class="components">
								{#each template.components.back as c}
									<svelte:component this={componentLookup[c.component]} {c} />
								{/each}
							</div>
						</div>
					{/if}
				</div>
			</div>
		</div>
	</div>
{/if}
