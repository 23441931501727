<script>

	import { onMount, tick } from 'svelte';
	import { slide } from "svelte/transition";

	import Unlock from './Unlock.svelte';
	import TriggerPermissions from './QR/TriggerPermissions.svelte';

	import Panel from './ui/Panel.svelte';
	import Field from './ui/Field.svelte';
	import Button from './ui/Button.svelte';
	import ButtonGroup from './ui/ButtonGroup.svelte';

	import { settings, showSettings, event, deviceRef, deviceName, offline, printers } from './lib/stores.js';
	import { getServerData, postServerData } from './lib/prelude.js';
	import { getUA } from './lib/device.js';
	import { db } from "./lib/db.js";
	import { walkInsAvailable } from "./lib/ticketTypes.js";

	let unlocked = false;
	let workingData = null;
	let mustAllowSearch = false;
	let selfService = false;
	let mounted = false;
	let offlinePrinters = {};
	let printerErr = null;
	let walkInErr = null;

	const ua = getUA();

	async function setWorkingData() {

		console.log('setWorkingData', $settings);

		if ($event && $event.setup && $event.setup.checkin) {

			workingData = JSON.parse(JSON.stringify($settings));

			if (!workingData.deviceName) {
				workingData.deviceName = $deviceName;
				await checkDeviceName();
			}

			if (!$event.setup.checkin.selfService) {
				workingData.staffed = true;
				workingData.mode = 'event';
			}

			if (!$event.setup.checkin.sessionCheckin) {
				workingData.mode = 'event';
			}

			if (!$event.setup.checkin.qrCodes) {
				workingData.qr = false;
			}

			if (!$event.setup.checkin.allowEdits) {
				workingData.allowEdits = false;
			}

			if (!workingData.badgeCollection) {
				workingData.badgeCollection = 'Please collect your badge from the front desk.';
			}

			if (!workingData.staffed) {
				selfService = true;
			}

			// if changes are forced or we're loading a new event, insta-save
			await saveSettings();

			mounted = true;

			if ($event.setup.checkin.onDemand && !$offline) {
				const printerData = await getServerData('/checkin/print/printers');
				if (printerData.printers) {
					let printersList = [];
					for (const p of printerData.printers) {
						if ((p.state != 'online') || (p.computer.state != 'connected')) {
							offlinePrinters[p.id] = true;
						}
						printersList.push({
							value: p.id,
							label: p.name + ' (' + p.computer.name + ')',
						});
					}
					$printers = printersList;
					checkPrinter();
				}
			}

		}

	}

	async function saveSettings() {

		const json = JSON.stringify(workingData);

		$settings = JSON.parse(json);
		$deviceName = workingData.deviceName;

		if ($offline) {

			const dt = new Date().toISOString().replace('Z',''); // UTC

			db.offline.add({
				action: 'settings',
				settings: json,
				dt: dt,
				event: $event.ref
			});

		} else {

			postServerData('/checkin/device', {
				settings: json
			});

		}

	}

	async function saveAndClose() {
		await saveSettings();
		$showSettings = false;
	}

	function checkQR() {
		if (workingData.qr) {
			mustAllowSearch = false;
		} else {
			mustAllowSearch = true;
			workingData.search = true;
		}
	}

	async function checkDeviceName() {
		// console.log('checkDeviceName',workingData.deviceName);
		if (workingData.deviceName == '') {
			const d = ua + ' ' + $deviceRef.substring(4,8);
			// console.log('checkDeviceName --> ',d);
			await tick();
			workingData.deviceName = d;
		}
	}

	function checkPrinter() {
		let err = null;
		if (workingData.printer) {
			if (offlinePrinters[workingData.printer]) {
				err = 'This printer is offline';
			}
		}
		printerErr = err;
	}

	function setStaffed() {
		if (mounted) {
			workingData.staffed = !selfService;
		}
	}

	function checkWalkIns() {
		let err = null;
		if ($offline) {
			err = 'Walk-in registrations are unavailable while this device is offline.';
		}
		if (!walkInsAvailable()) {
			err = 'No tickets are currently available for walk-in registrations.';
		}
		walkInErr = err;
	}

	$: if (unlocked && $event) {
		setWorkingData();
	}

	$: if (workingData) {
		checkQR();
		checkDeviceName();
		checkPrinter();
	}

	$: setStaffed(selfService);

	$: checkWalkIns($event,$offline)

</script>

<style>
	.actions {
		padding-left: 2rem;
		padding-right: 2rem;
		padding-bottom: 1.4rem;
	}
</style>

{#if unlocked && workingData}
	<Panel title="Settings for this device" on:close={() => { $showSettings = false }}>

		<TriggerPermissions/>

		<form id="settingsForm" on:submit|preventDefault={saveAndClose} style="--font: 'Inter', sans-serif;">
			{#if mounted}

				<Field
					bind:value={workingData.deviceName}
					component="TextField"
					label="Device name"
				/>

				{#if $event.setup.checkin.selfService}
					<Field
						bind:value={selfService}
						component="Switch"
						label="Self-service check-in"
					/>
				{/if}

				{#if workingData.staffed && $event.setup.checkin.sessionCheckin}
					<div transition:slide|local={{ duration: 200 }}>
						<Field
							bind:value={workingData.mode}
							component="SelectList"
							label="Check-in mode"
							options={[
								{ value: 'event', label: 'Whole event' },
								{ value: 'session', label: 'Session only' }
							]}
						/>
					</div>
				{/if}

				{#if workingData.mode == 'session'}
					<div transition:slide|local={{ duration: 200 }}>
						<span>TODO: session...</span>
					</div>
				{/if}

				{#if $event.setup.checkin.qrCodes}
					<Field
						bind:value={workingData.qr}
						component="Switch"
						label="Use QR code scanning"
					/>
				{/if}

				{#if !workingData.staffed}
					<div transition:slide|local={{ duration: 200 }}>
						<Field
							bind:value={workingData.search}
							component="Switch"
							label="Use attendee search"
							disabled={mustAllowSearch}
						/>
					</div>
				{/if}

				{#if $event.setup.checkin.allowRegistrations}
					<Field
						bind:value={workingData.allowRegistrations}
						component="Switch"
						label="Allow new registrations"
						err={walkInErr}
						errIgnoresFocus={true}
					/>
				{/if}

				{#if $event.setup.checkin.allowEdits}
					<Field
						bind:value={workingData.allowEdits}
						component="Switch"
						label="Allow badge edits"
					/>
				{/if}

				{#if $event.setup.checkin.onDemand && $printers.length && !$offline}
					<Field
						bind:value={workingData.printer}
						component="SelectList"
						label="On-demand badge printer"
						options={$printers}
						nullOption="None"
						err={printerErr}
					/>
				{/if}

				{#if !workingData.staffed}
					<div transition:slide|local={{ duration: 200 }}>
						<Field
							bind:value={workingData.badgeCollection}
							component="TextArea"
							label="Badge collection instructions"
						/>
					</div>
				{/if}

			{/if}
		</form>

		<div class="actions" slot="actions">
			<ButtonGroup wide={false} center={false}>
				<Button type="submit" form="settingsForm" label="Save settings" double={true} green={true}>
					<svg viewBox="0 0 80 80"><path d="M28.8 59.6l-12.6-12.6 4.3-4.2 8.3 8.3 30.7-30.7 4.3 4.2z"/></svg>
				</Button>
			</ButtonGroup>
		</div>

	</Panel>
{:else}
	<Panel on:close={() => { $showSettings = false }}>
		<Unlock bind:unlocked on:reset/>
	</Panel>
{/if}
