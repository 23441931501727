<script>

	import { onMount } from 'svelte';
	import { tweened } from 'svelte/motion';
	import { cubicOut } from 'svelte/easing';

	import { liveQuery } from "dexie";

	import dayjs from "dayjs";
	import utc from "dayjs/plugin/utc";
	import timezone from "dayjs/plugin/timezone";
	dayjs.extend(utc);
	dayjs.extend(timezone);

	import FormattedNumber from '../ui/FormattedNumber.svelte';
	import RadialProgress from "../ui/RadialProgress.svelte";

	import { settings, event, offline, lastOnline } from '../lib/stores.js';
	import { db } from "../lib/db.js";

	let mounted = false;

	let attendees = liveQuery(
		() => db.attendees.filter(r => {	return !r.isVirtual }).count()
	);

	let checkedin = liveQuery(
		() => db.attendees.where('status').equals('checked-in').filter(r => { return !r.isVirtual }).count()
	);

	const percentage = tweened(0, {
		duration: 600,
		easing: cubicOut
	});

	let displayPercentage = 0;

	function calcPercentage() {
		let p = ($checkedin / $attendees) * 100;
		if (isNaN(p)) {
			$percentage = 0;
		} else {
			$percentage = Number(p);
		}
		displayPercentage = Number($percentage).toFixed(0);
	}

	$: if ($event && $event.ref) {
		mounted = true;
	}

	$: calcPercentage($attendees, $checkedin);

</script>

<style>
	.stats {
		flex: 1 0 12rem;
		background: var(--blend-05);
		border-radius: 6px;
		padding: 0.8rem;
		box-sizing: border-box;
		color: var(--textColor);
		text-align: left;
		overflow-y: auto;
		max-height: 100%;
	}

	@media (max-width: 640px) {
		.stats {
			flex: 1 0 100%;
			height: min-content;
		}
	}

	:global(.panel.short) .event {
		padding-right: 2rem;
	}

	.event {
		font-size: 1.2rem;
		line-height: 1.3;
	}

	.date {
		margin: 0.6rem 0;
		font-size: 0.9rem;
	}

	.status {
		display: flex;
		flex-wrap: wrap;
		gap: 2rem;
		margin: 3rem 0 1rem 0;
		align-items: center;
	}

	.doughnut {
		flex: 0 0 120px;
	}

	.doughnut > div {
		display: block;
		position: relative;
		width: 100%;
		aspect-ratio: 1 / 1;
	}

	.doughnut > div > :global(div) {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.counter {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		font-size: 1.6rem;
		display: grid;
		place-content: center;
		margin: 0;
		font-weight: 200;
		color: var(--accentColor);
	}

	@media (max-width: 640px) {
		.stats {
			background: transparent;
			padding: 0;
		}
	}

	@media (min-width: 640px) {
		.doughnut + div {
			flex: 1 0 100%;
		}
	}

	.stat {
		font-size: 2.6rem;
		line-height: 1.2;
		color: var(--accentColor);
	}

	.stat + .stat {
		margin: 1rem 0 0 0;
	}

	.stat .label {
		display: block;
		font-size: 0.8rem;
/*		text-transform: uppercase;*/
		color: var(--textColor);
	}

	.offline {
		margin: 2rem 0 0 0;
		border-top: 1px solid var(--blend-20);
		padding-top: 0.5rem;
		color: var(--errorColor);
	}

	.offline strong {
		font-size: 0.9rem;
/*		text-transform: uppercase;*/
		position: relative;
		top: -0.2rem;
	}

	.offline span {
		display: block;
		margin: 0.2rem 0 0 0;
		font-size: 0.7rem;
		color: var(--blend-40);
	}

	.offline svg {
		width: 1.2rem;
	}
</style>

<div class="stats">
	{#if mounted}

		<p class="event">
			<strong>
				{$event.name}
				<!-- 15th Annual Mercedes-Benz Press Dealer Launch -->
			</strong>
		</p>

		{#if $event.starts && $event.ends && $event.setup && $event.setup.timeZone}
			<p class="date">
				{#if $event.starts == $event.ends}
					{ dayjs.utc($event.starts).tz($event.setup.timeZone).format('DD MMMM YYYY') }
				{:else if (dayjs.utc($event.starts).year() == dayjs.utc($event.ends).year()) && (dayjs.utc($event.starts).month() == dayjs.utc($event.ends).month()) }
					{ dayjs.utc($event.starts).tz($event.setup.timeZone).format('DD') + '–' + dayjs.utc($event.ends).tz($event.setup.timeZone).format('DD MMMM YYYY') }
				{:else if dayjs.utc($event.starts).year() == dayjs.utc($event.ends).year() }
					{ dayjs.utc($event.starts).tz($event.setup.timeZone).format('DD MMMM') + '–' + dayjs.utc($event.ends).tz($event.setup.timeZone).format('DD MMMM YYYY') }
				{:else}
					{ dayjs.utc($event.starts).tz($event.setup.timeZone).format('DD MMMM YYYY') + '–' + dayjs.utc($event.ends).tz($event.setup.timeZone).format('DD MMMM YYYY') }
				{/if}
			</p>
		{/if}

		<div class="status">

			<div class="doughnut">
				<div>
					<RadialProgress value={$percentage}>
						<span class="counter"><span><strong>{displayPercentage}</strong>%</span></span>
					</RadialProgress>
				</div>
			</div>

			<div>
				<p class="stat">
					<FormattedNumber number={$checkedin}/>
					<span class="label">Checked in</span>
				</p>
				<p class="stat">
					<FormattedNumber number={$attendees}/>
					<span class="label">Registered</span>
				</p>
			</div>

		</div>

		{#if $offline}
			<p class="offline">
				<svg viewBox="0 0 56 56"><path d="M3.73 52.65c-1.25-.03-2.45-.69-3.12-1.85-.67-1.16-.64-2.53-.04-3.63l24.26-42.04c.65-1.07 1.83-1.78 3.17-1.78 1.34 0 2.52.71 3.17 1.78l24.27 42.04c.6 1.1.63 2.47-.04 3.63-.67 1.16-1.87 1.82-3.12 1.85h-48.55zm24.27-43.08l-22.06 38.21h44.12l-22.06-38.21zM28 40.31c1.48 0 2.7 1.22 2.7 2.7s-1.22 2.7-2.7 2.7-2.7-1.22-2.7-2.7 1.22-2.7 2.7-2.7zm-2.33-18.91c0-1.54.9-2.49 2.33-2.49s2.33.95 2.33 2.49v14.43c0 1.54-.9 2.49-2.33 2.49s-2.33-.95-2.33-2.49v-14.43z"/></svg>
				<strong>Offline</strong>
				{#if $lastOnline}
					<span>Last online at { dayjs($lastOnline).format('HH:mm') }</span>
				{/if}
			</p>
		{/if}

	{/if}
</div>