import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

export const isYetToCome = date => {
	let dt = dayjs.utc(date);
	let now = dayjs.utc();
	return dt.isAfter(now) ? true : false;
};

export const showDate = (date, fmt) => {
	// Displays in local time...
	let dt = date ? dayjs.utc(date) : dayjs();
	if (!fmt) fmt = 'DD MMM YY [at] HH:mm';
	return dt.format(fmt);
};