import tinycolor from "tinycolor2";

export const colorMix = (color1, color2, amount) => {
	amount = 100 - amount;
	return tinycolor.mix(color1, color2, amount).toHexString();
};

export const getErrorColor = (colors) => {

	// colors[0] should be the background
	const bg = colors.shift();

	// console.log('getErrorColor', bg);

	// are any of the colours already red?
	// (get the most red)

	let reds = {};

	for (const c of colors) {
		if (c) {
			let red = isRed(c);
			// if (red && (getContrast(c, bg) > 2)) reds[c] = red;
			// 31 May 2023 -- if there isn't enough contrast, try to force it (but only a little)
			if (red) {
				if (getContrast(c, bg) > 2) {
					reds[c] = red;
				} else if (isPale(bg)) {
					let darker = tinycolor(c).darken(15).toString();
					if (getContrast(darker, bg) > 2) {
						reds[darker] = red;
					}
				} else {
					let brighter = tinycolor(c).lighten(15).toString();
					if (getContrast(brighter, bg) > 2) {
						reds[brighter] = red;
					}
				}
			}
		}
	}

	if (Object.keys(reds).length) {
		let sorted = Object.entries(reds).sort((a, b) => b[1] - a[1]);
		// console.log('reds',sorted);
		return sorted[0][0];
	}

	// add a default red to the choices, if we have contrast for it
	// use a bigger contrast threshold here
	const dx = '#d63430';
	const dxr = isRed(dx);
	const dxc = getContrast(dx, bg);
	// console.log('contrast with panelColor', bg, dxr, dxc);
	if (dxc > 4) reds[dx] = dxr;

	// get hue rotations
	for (const c of colors) {
		if (c) {
			for (const a of [60,120,180,240,300]) {
				const cx = tinycolor(c).spin(a).toHexString();
				const cxr = isRed(cx);
				const cxc = getContrast(cx, bg);
				// console.log(cx,cxr,cxc);
				if (cxr && (cxc > 2)) reds[cx] = cxr;
			}
		}
	}

	if (Object.keys(reds).length) {
		let sorted = Object.entries(reds).sort((a, b) => b[1] - a[1]);
		// console.log('reds',sorted);
		return sorted[0][0];
	}

	// fallback
	return colors[0];

};

const isRed = (color) => {
	let tc = tinycolor(color);
	let hsv = tc.toHsv();
	let hue = hsv.h;
	if (hsv.s > 0.4) { // not too grey
		let r = 0;
		if (hsv.h > 320) r = (100 - (360 - hsv.h));
		if (hsv.h < 20)  r = (100 - hsv.h);
		// console.log(color, hsv.h, r);
		return r;
	}
	return 0;
};

export const getSuccessColor = (colors) => {

	// colors[0] should be the background
	const bg = colors.shift();

	console.log('getSuccessColor',bg);

	// are any of the colours already green?
	// (get the most green)

	let greens = {};

	for (const c of colors) {
		if (c) {
			let green = isGreen(c);
			if (green && (getContrast(c, bg) > 2)) greens[c] = green;
		}
	}

	if (Object.keys(greens).length) {
		let sorted = Object.entries(greens).sort((a, b) => b[1] - a[1]);
		return sorted[0][0];
	}

	// add a default green to the choices, if we have contrast for it
	// use a bigger contrast threshold here
	const dx = '#77b82a';
	const dxr = isGreen(dx);
	const dxc = getContrast(dx, bg);
	// console.log('contrast with panelColor', bg, dxr, dxc);
	if (dxc > 4) greens[dx] = dxr;

	// get hue rotations
	for (const c of colors) {
		if (c) {
			for (const a of [60,120,180,240,300]) {
				const cx = tinycolor(c).spin(a).toHexString();
				const cxr = isGreen(cx);
				const cxc = getContrast(cx, bg);
				// console.log(cx,cxr,cxc);
				if (cxr && (cxc > 2)) greens[cx] = cxr;
			}
		}
	}

	if (Object.keys(greens).length) {
		let sorted = Object.entries(greens).sort((a, b) => b[1] - a[1]);
		// //console.log(sorted);
		return sorted[0][0];
	}

	// fallback
	return colors[0];

};

const isGreen = (color) => {
	let tc = tinycolor(color);
	let hsv = tc.toHsv();
	let hue = hsv.h;
	if (hsv.s > 0.4) { // not too grey
		let g = 0;
		if ((hsv.h > 60) && (hsv.h < 120)) g = (120 - hsv.h);
		if ((hsv.h >= 120) && (hsv.h < 180)) g = (hsv.h - 120);
		// console.log(color, hsv.h, g);
		return g;
	}
	return 0;
};

export const isDark = (color) => {
	if (!color) return null;
	return (getBrightness(color) < 140) ? true : false;
};

export const isPale = (color) => {
	if (!color) return null;
	return (getBrightness(color) > 180) ? true : false;
};

export const getBrightness = (color) => {
	if (!color) return null;
	let tc = tinycolor(color);
	return tc.getBrightness();
};

export const darken = (color) => {
	let tc = tinycolor(color);
	tc = tc.darken(25);
	return isDark(tc) ? tc.toHexString() : darken(tc);
};

export const getContrast = (color1, color2) => {
	return tinycolor.readability(color1, color2);
};

export const bestContrast = (basis, candidate1, candidate2, candidate3) => {

	// //console.log('bestContrast:', basis, candidate1[1], candidate2[1]);

	// basis is a plain color
	// each candidate is an array [name,color]
	// compare the candidate colors and return the candidate name
	// the first candidate ought to be the background color; we skew to this

	let brightness1 = tinycolor(candidate1[1]).getBrightness();
	let brightness2 = tinycolor(candidate2[1]).getBrightness();

	const contrast1 = getContrast(basis, candidate1[1]);
	const contrast2 = getContrast(basis, candidate2[1]);

	// //console.log(candidate1[0], contrast1, brightness1);
	// //console.log(candidate2[0], contrast2, brightness2);

	// if either contrast is really low, always use the best one
	// otherwise skew to the brighter color unless the difference is high
	// 13 Jul 2021 -- skew to the brighter color unless the difference is **really** high

	let winner;

	if ((contrast1 < 2) || (contrast2 < 2)) {
		winner = (contrast2 > contrast1) ? candidate2 : candidate1;
	} else if (brightness2 > brightness1) {
		// winner = (contrast1 > (contrast2 + 1.8)) ? candidate1 : candidate2;
		winner = (contrast1 > (contrast2 + 4)) ? candidate1 : candidate2;
	} else {
		// winner = (contrast2 > (contrast1 + 1.8)) ? candidate2 : candidate1;
		winner = (contrast2 > (contrast1 + 4)) ? candidate2 : candidate1;
	}

	if (candidate3 && (candidate3[1] != candidate1[1] && candidate3[1] != candidate2[1])) {
		return bestContrast(basis, winner, candidate3);
	} else {
		return winner[0];
	}

};

export const bestContrastHex = (basis, candidate1, candidate2, candidate3) => {

	// As above, but hex only

	let brightness1 = tinycolor(candidate1).getBrightness();
	let brightness2 = tinycolor(candidate2).getBrightness();

	const contrast1 = getContrast(basis, candidate1);
	const contrast2 = getContrast(basis, candidate2);

	let winner;

	if ((contrast1 < 2) || (contrast2 < 2)) {
		winner = (contrast2 > contrast1) ? candidate2 : candidate1;
	} else if (brightness2 > brightness1) {
		winner = (contrast1 > (contrast2 + 4)) ? candidate1 : candidate2;
	} else {
		winner = (contrast2 > (contrast1 + 4)) ? candidate2 : candidate1;
	}

	if (candidate3 && (candidate3 != candidate1 && candidate3 != candidate2)) {
		return bestContrastHex(basis, winner, candidate3);
	} else {
		return winner;
	}

};

export const getNewColor = (color) => {
	return tinycolor(color).spin(60).toHexString();
};

export const greyscale = (color) => {
	return tinycolor(color).greyscale().toHexString();
};

