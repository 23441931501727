<script>
	
	import { onMount } from 'svelte';
	import { fade, fly, scale, crossfade } from 'svelte/transition';
	import { flip } from 'svelte/animate';
	import { quintOut } from 'svelte/easing';
	import { readable } from 'svelte/store';

	import { liveQuery } from "dexie";

	import Stats from './Search/Stats.svelte';
	import MicroStats from './Search/MicroStats.svelte';
	import Button from './ui/Button.svelte';
	import ButtonGroup from './ui/ButtonGroup.svelte';
	import Panel from './ui/Panel.svelte';
	import Spinner from './ui/Spinner.svelte';
	import Actions from './Search/Actions.svelte';

	import {
		screen,
		attendee,
		settings,
		showSettings,
		showRegform,
		event,
		isNarrowScreen
	} from './lib/stores.js';

	import { db } from "./lib/db.js";
	import { isVIP } from "./lib/vips.js";
	import { autoblur } from './lib/autoblur.js';
	import { isTouchDevice } from './lib/device.js';

	let input;
	let query = '';
	let queryBuffer;
	let fetching = false;
	let results;

	let minLength = $settings.staffed ? 0 : 2;
	let prompt = $settings.staffed ? 'Search for attendees' : 'Enter your name';

	let statsPanel = false;

	const [send, receive] = crossfade({
		fallback: fly
	});

	onMount(() => {
		if (!($settings.staffed && isTouchDevice())) {
			input.focus();
		}
	});

	function bufferSearch(q) {
		clearTimeout(queryBuffer);
		queryBuffer = setTimeout(() => {
			search(q);
		}, 400);
	}

	function search(q) {
		if (q.length > minLength) {
			results = liveQuery(async () => {
				if (q) fetching = true;
				const results = await db.attendees
					.where('n').startsWithIgnoreCase(q)
					.or('f').startsWithIgnoreCase(q)
					.or('l').startsWithIgnoreCase(q)
					.or('e').startsWithIgnoreCase(q)
					.or('oWords').startsWithIgnoreCase(q)
					.or('attendeeCode').equalsIgnoreCase(q)
					.filter(r => {
						return !r.isVirtual
					})
					.distinct()
					.sortBy('l');
				fetching = false;
				return results;
			});
		} else {
			if ($settings.staffed) {
				results = liveQuery(async () => {
					if (q) fetching = true;
					const results = await db.attendees
						.filter(r => {
							return !r.isVirtual
						})
						.sortBy('l');
					fetching = false;
					return results;
				});
			} else {
				results = readable([]);
			}
		}
	}

	function setAttendee(a) {
		$attendee = a;
	}

	$: bufferSearch(query);

	// $: console.log('fetching',fetching);

</script>

<style>
	.outer {
		height: 100%;
		position: relative;
	}
	.wrapper {
		position: absolute;
		inset: 0;
		display: flex;
		flex-direction: column;
		box-sizing: border-box;
		padding-bottom: 2rem;
		max-width: 600px;
		margin: 0 auto;
		padding-top: 3rem;
	}
	.wrapper.staffed {
		padding-top: 0;
		max-width: unset;
	}
	.wrapper > div {
		flex: 1 1 auto;
		display: flex;
		flex-wrap: wrap;
		gap: 0.5rem 2rem;
		overflow-y: auto;
		position: relative;
	}
	.search {
		flex: 1 1 calc(100% - 14rem);
		position: relative;
	}
	.search > div {
		position: absolute;
		inset: 0;
		display: flex;
		flex-direction: column;
	}
	p {
		text-align: left;
		position: relative;
	}
	label {
		/*display: block;
		margin-bottom: 0.8rem;
		font-size: 0.9rem;
		color: var(--labelColor);*/
		position: absolute;
		top: 0.7rem;
		left: 0.6rem;
		width: 2rem;
		color: var(--textColor);
	}
	input {
		display: block;
		background: var(--blend-05);
		color: var(--textColor);
		font-size: 1rem;
		border: 1px solid transparent;
		border-radius: 6px;
		width: 100%;
		box-sizing: border-box;
		padding: 1rem 1rem 1rem 2.8rem;
	}
	input:focus {
		outline: 0;
		border-color: var(--accentColor);
	}
	input::placeholder {
		color: var(--textColor);
		opacity: 1;
	}
	.results {
		position: relative;
		flex: 1 1 auto;
		overflow-y: auto;
		background:
			linear-gradient(var(--bgColor) 30%, transparent),
			linear-gradient(transparent, var(--bgColor) 70%) 0 100%,
			linear-gradient(var(--shadowColor), transparent),
			linear-gradient(transparent, var(--shadowColor)) 0 100%;
		background-repeat: no-repeat;
		background-color: var(--bgColor);
		background-size: 100% 40px, 100% 40px, 100% 1rem, 100% 1rem;
		background-attachment: local, local, scroll, scroll;
		-webkit-transform: translateZ(0);
		transition: opacity 0.4s ease;
		transition-delay: 0.2s;
	}
	/*.results.fetching {
		opacity: 0.4;
	}*/
	.results > ul {
		position: relative;
		z-index: 10;
		display: block;
		margin: 0 auto;
		/*width: 100%;
		max-width: calc(800px + 2rem);
		padding: 0 1rem;*/
		padding: 1rem 0 0 0;
		box-sizing: border-box;
	}
	.results > ul:before {
		content: '';
		position: absolute;
		top: -20px;
		bottom: -20px;
		left: 0;
		width: 100%;
		background: transparent;
		box-shadow: 0 0 20px 20px var(--bgColor) inset;
		z-index: -1;
	}
	li {
		display: block;
		margin: 0;
		padding: 0.2rem 0;
	}
	li + li {
		border-top: 1px solid var(--blend-10);
	}
	button {
		display: block;
		width: 100%;
		box-sizing: border-box;
		padding: 0.4rem 0.8rem;
		border: 0;
		background: transparent;
		margin: 0;
		text-align: left;
		color: var(--textColor);
		font-size: 1.2rem;
		cursor: pointer;
		line-height: 1.4;
		border-radius: 4px;
	}
	@media (hover: hover) and (pointer: fine) {
		button:hover {
			background: var(--blend-05);
		}
	}
	.company {
		display: block;
		opacity: 0.9;
		font-size: 0.8rem;
		white-space: nowrap;
    	text-overflow: ellipsis;
    	overflow: hidden;
	}

	.staffed button {
		position: relative;
		padding-left: 2.8rem;
	}

	.staffed button .checkedin {
		position: absolute;
		top: 0.6rem;
		left: 0.8rem;
		width: 1.2rem;
		height: 1.2rem;
		color: var(--successColor);
	}

	.staffed button .vip {
		display: inline-block;
		position: relative;
		width: 1rem;
		color: var(--textColor);
		margin-left: 0.3rem;
	}

	.wrapper > div > :global(button.icon) {
		position: absolute;
		bottom: 1rem;
		right: 1rem;
		z-index: 900;
		box-shadow: 0 1px 6px var(--darkShadowColor);
		background: var(--accentColor);
		width: 3rem;
		height: 3rem;
	}

	.wrapper > div > :global(button.icon svg) {
		width: 2.4rem;
		height: 2.4rem;
	}

	.fetching {
		position: absolute;
		inset: 0;
		display: grid;
		place-content: center;
		z-index: 10000;
	}
	.fetching:before {
		content: '';
		position: fixed;
		inset: 0;
		background: var(--bgColor);
		opacity: 0.9;
		pointer-events: none;
	}

</style>

<!-- class:results={$results && $results.length} -->
<div class="outer">
	<div class="wrapper" in:fly={{ duration: 200, y: 10, delay: 100 }} class:staffed={$settings.staffed}>
		<div>

			{#if $settings.staffed}
				{#if $isNarrowScreen}
					<Button icon={true} on:click={() => { statsPanel = true }}>
						<MicroStats/>
					</Button>
				{:else}
					<Stats/>
				{/if}
			{/if}

			<div class="search">
				<div>

					<p>
						<label for="query">
							<svg viewBox="0 0 80 80"><title>Search</title><path d="M64.1 59.9l-15-15c2.2-3.1 3.6-6.9 3.6-11 0-10.4-8.4-18.8-18.8-18.8-10.5-.1-18.9 8.3-18.9 18.7s8.4 18.8 18.8 18.8c4.1 0 7.9-1.3 11-3.6l15 15c1.2 1.2 3.1 1.2 4.2 0s1.3-3 .1-4.1zm-45.1-26.1c0-8.1 6.7-14.8 14.8-14.8s14.8 6.7 14.8 14.8-6.6 14.9-14.8 14.9-14.8-6.7-14.8-14.9z"/></svg>
						</label>
						<input
							id="query"
							type="text"
							bind:this={input}
							bind:value={query}
							autocorrect="off"
							autocapitalize="none"
							autocomplete="off"
							spellcheck="false"
							placeholder={prompt}
						/>
					</p>

					<div class="results">
						{#if $results}
							<ul in:fly={{ duration: 200, y: 10 }}>
								{#each $results as result, i (result.ref)}
									<!-- <li
										in:receive|local="{{ key: result.ref,  y:20, duration: 200, delay: (40 * i) }}"
										out:send|local="{{ key: result.ref, duration: 0 }}"
										animate:flip|local="{{ duration: 200 }}"
									> -->
									<li>
										<button type="button" on:click={setAttendee(result)} use:autoblur>
											{#if $settings.staffed && (result.status == 'checked-in')}
												<span class="checkedin">
													<svg viewBox="0 0 60 60"><path d="M30 0c16.5 0 30 13.5 30 30s-13.5 30-30 30-30-13.5-30-30 13.5-30 30-30zm0 56c14.3 0 26-11.7 26-26s-11.7-26-26-26-26 11.7-26 26 11.7 26 26 26zM25.3 43.2l-12.2-12.2 4.3-4.2 7.9 7.9 17.2-17.2 4.3 4.3z"/></svg>
												</span>
											{/if}
											{#if $settings.staffed}
												<strong>{result.l}</strong>, {result.f}
											{:else}
												<strong>{result.f} {result.l}</strong>
											{/if}
											{#if $settings.staffed && isVIP(result.ref)}
												<span class="vip">
													<svg viewBox="0 0 47.4 45.1"><path d="M44.6 17.9m-20.9-17.9c-1.2 0-2.2.7-2.6 1.9l-4.3 13.3h-14c-1.2 0-2.2.7-2.6 1.9-.4 1.1 0 2.3 1 3l11.3 8.2-4.3 13.3c-.4 1.1 0 2.3 1 3 .5.3 1 .5 1.6.5s1.1-.2 1.6-.5l11.3-8.2 11.3 8.2c.5.3 1 .5 1.6.5s1.1-.2 1.6-.5c.9-.7 1.3-1.9 1-3l-4.3-13.3 11.3-8.2c.7-.5 1.2-1.3 1.2-2.2 0-1.5-1.2-2.7-2.7-2.7h-14l-4.3-13.3c-.5-1.2-1.5-1.9-2.7-1.9z"/></svg>
												</span>
											{/if}
											{#if result.o}<span class="company">{result.o}</span>{/if}
										</button>
									</li>
								{/each}
							</ul>
						{/if}
						{#if fetching}
							<div class="fetching" in:fade|local={{ delay:600, duration:300 }} out:fade|local={{ duration:300 }}>
								<Spinner size="80" speed="1200" thickness="1" gap="30"/>
							</div>
						{/if}
					</div>

				</div>
			</div>

		</div>

		{#if $settings.staffed}
			{#if $settings.qr || ($event?.setup?.checkin?.allowRegistrations && $settings.allowRegistrations)}
				<Actions/>
			{/if}
		{:else}
			<ButtonGroup wide={false}>
				<Button ghost={true} double={true} on:click={() => { $screen = undefined }} label="Cancel">
					<svg viewBox="0 0 80 80"><path d="M24.4 20.2l-4.2 4.2 15.6 15.6-15.6 15.6 4.2 4.2 15.6-15.6 15.5 15.6 4.3-4.3-15.6-15.5 15.6-15.6-4.2-4.2-15.6 15.6z"/></svg>
				</Button>
			</ButtonGroup>
		{/if}

	</div>
</div>

{#if statsPanel}
	<Panel short={true} pullup={true} on:close={() => { statsPanel = false }}>
		<Stats/>
	</Panel>
{/if}