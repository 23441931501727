<script>

	// Note:
	// Checkout opens in an in-app browser view
	// View has Safari button, but this is inert in guided-access mode
	// Checkout redirects back to checkin.attendzen.io which reverts to standalone view

	import { onMount, onDestroy } from 'svelte';
	import { writable } from 'svelte/store';
	import { fade } from 'svelte/transition';

	import cssVars from 'svelte-css-vars';

	import { liveQuery } from "dexie";

	import Panel from './ui/Panel.svelte';
	import Button from './ui/Button.svelte';
	import ButtonGroup from './ui/ButtonGroup.svelte';

	import { event, showRegform, appElement, attendee } from './lib/stores.js';
	import { db } from "./lib/db.js";

	let vars = {};
	let registered = writable(null);
	let newAttendee = writable(null);

	onMount(() => {
		vars.bgColor = getComputedStyle($appElement).getPropertyValue('--blend-x');
		vars.textColor = getComputedStyle($appElement).getPropertyValue('--textColor');
		vars.linkColor = getComputedStyle($appElement).getPropertyValue('--accentColor');
		vars.headingColor = getComputedStyle($appElement).getPropertyValue('--textColor');
		vars.labelColor = getComputedStyle($appElement).getPropertyValue('--labelColor');
		vars.dividerColor = getComputedStyle($appElement).getPropertyValue('--textColor');
		vars.buttonTextColor = getComputedStyle($appElement).getPropertyValue('--buttonTextColor');
		vars.errorColor = getComputedStyle($appElement).getPropertyValue('--errorColor');
		vars.successColor = getComputedStyle($appElement).getPropertyValue('--successColor');
		setTimeout(() => {
			const regform = new aznr({
				target: document.getElementById("aznr"),
				props: {
					checkinAttendee: registered
				}
			});
		}, 600);
	});

	onDestroy(() => {
		sessionStorage.removeItem("aznrAuthToken");
		history.replaceState("", document.title, window.location.pathname + window.location.search);
	});

	function checkRegistered() {
		console.log('data from regform', $registered);
		if ($registered[1] && $registered[1].responseSets && ($registered[1].responseSets.length == 1)) {
			// console.log('found attendee responseSet', $registered[1].responseSets[0].components);
			let attendeeEmail;
			for (const c of $registered[1].responseSets[0].components) {
				// console.log('component', c.hook, c.value);
				if (c.hook == 'e') {
					attendeeEmail = c.value;
					break;
				}
			}
			if (attendeeEmail) {
				console.log('attendeeEmail', attendeeEmail);
				newAttendee = liveQuery(async () => {
					const results = await db.attendees
						.where('e').equalsIgnoreCase(attendeeEmail)
						.first();
					return results;
				});
			}
		}
	}

	function instaCheckin() {
		const a = JSON.parse(JSON.stringify($newAttendee));
		a.instaCheckin = true;
		$attendee = a;
		setTimeout(() => {
			$showRegform = false;
		}, 400);
	}

	$: if ($registered) {
		checkRegistered();
	}

	// $: if ($newAttendee) {
	// 	console.log('new attendee!', $newAttendee);
	// 	instaCheckin();
	// }

</script>

<style>
	.actions :global(.buttonGroup) {
		padding: 1.4rem 2rem;
	}
	.fader {
		width: 100%;
		text-align: center;
	}
</style>

<svelte:head>
	<link rel="stylesheet" href="https://sites.attendzen.io/res/display/default/css/aznr.css">
	{#if ($event && $event.theme && $event.theme.slug)}
		<link rel="stylesheet" href="https://res.attendzen.io/css/{$event.theme.slug}-aznr.css">
	{/if}
</svelte:head>

<Panel title="Register" on:close={() => { $showRegform = false }}>
	<div id="aznr" data-event={$event.token} use:cssVars={vars}></div>
	<div class="actions" slot="actions">
		{#if $newAttendee}
			<ButtonGroup wide={true} center={true}>
				<div class="fader" in:fade={{ delay: 300, duration: 400 }}>
					<Button on:click={instaCheckin} label="Check in" double={true}/>
				</div>
			</ButtonGroup>
		{/if}
	</div>
</Panel>