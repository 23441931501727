<script>

	import { fade } from "svelte/transition";

	import Button from './Button.svelte';

	import { modal } from '../lib/stores.js';

	function handleOpt(opt) {
		if (opt.f) opt.f();
		setTimeout(function(){
			modal.set(undefined);
		}, 100);
	}

	function handleKeydown(ev) {
		const key = ev.key;
		if (key === "Escape") {
			if ($modal.hasCancel) {
				modal.set(undefined);
			}
		}
	}

</script>

<style>
	#modal {
		margin: 30px;
		max-width: min(640px, 90vw);
		border: 1px solid var(--blend-20);
		background: var(--bgColor);
		color: var(--textColor);
		padding: 1.5rem 1.5rem 2rem 1.5rem;
		box-sizing: border-box;
		box-shadow: 0 1rem 2rem var(--darkShadowColor);
		border-radius: 4px;
		display: flex;
		flex-direction: column;
		position: relative;
		line-height: 1.3;
		text-align: left;
	}
	/*#modal.error {
		padding-left: 4.5rem;
	}
	#modal img {
		position: absolute;
		left: 1.5rem;
		top: 1.4rem;
		width: 2rem;
		height: 2rem;
	}*/
	#modal p {
		margin: 0;
	}
	#modal p strong {
		font-size: 1.2rem;
	}
	#modal p + p {
		margin-top: 1rem;
	}
	#modal .buttons {
		margin-top: 2rem;
		display: flex;
		gap: 1rem;
		flex-wrap: wrap;
	}
	/*#modal .buttons button + button {
		margin-left: .5rem;
	}*/
	/*#modal .buttons button + button.right {
		margin-left: auto;
	}*/
</style>

<svelte:body on:keydown|preventDefault|stopPropagation={handleKeydown}/>

{#if $modal}
	<div id="modal" class="panel" in:fade|local={{duration:100}} out:fade|local={{duration:200}}>
		<p><strong>{$modal.message}</strong></p>
		{#each $modal.details as d}
			{#if $modal.htmlDetails}
				<p>{@html d}</p>
			{:else}
				<p>{d}</p>
			{/if}
		{/each}
		{#if $modal.opts}
			<p class="buttons">
				{#each $modal.opts as opt}
					<Button label={opt.name} {...opt.display} on:click={() => { handleOpt(opt) }} mini={true} />
				{/each}
			</p>
		{/if}
	</div>
{/if}