<script>

	// Largely, though by no means entirely, nicked from Sverdle

	import { createEventDispatcher } from 'svelte';

	const dispatch = createEventDispatcher();

	function keydown(event) {
		if (event.metaKey) return;
		document
			.querySelector(`[data-key="${event.key}" i]`)
			?.dispatchEvent(new MouseEvent('mousedown', { cancelable: true }));
	}

	function pressed(event) {
		event.target.classList.add('pressed');
		setTimeout(() => {
			event.target.classList.remove('pressed');
		}, 200);
		const key = event.target.getAttribute('data-key');
		dispatch('press', key);
	}

	function autoblur(event) {
		event.target.blur();
	}

</script>

<style>
	.keyboard {
		--gap: 0.4rem;
		position: relative;
		display: flex;
		flex-direction: column;
		gap: var(--gap);
		padding: 2rem 0;
		user-select: none;
		font-family: 'Inter', sans-serif;
	}
	.keyboard .row {
		display: flex;
		justify-content: center;
		gap: var(--gap);
		flex: 1;
	}
	.keyboard button,
	.keyboard button:disabled {
		--size: min(7vw, 6vh, 60px);
		font-family: Inter, sans-serif;
		width: var(--size);
		height: var(--size);
		min-height: 2.8rem;
		border: none;
		border-radius: 2px;
		font-size: calc(var(--size) * 0.5);
		margin: 0;
		font-weight: 700;
		background: var(--blend-10, #eee);
		color: var(--textColor);
		box-sizing: border-box;
		display: grid;
		place-content: center;
		line-height: 0;
	}
	.keyboard :global(button.pressed) {
		background: var(--blend-20, #ddd);
	}
	@media (hover: hover) and (pointer: fine) {
		.keyboard :global(button:hover) {
			background: var(--blend-20, #ddd);
		}
	}
	.keyboard button[data-key='backspace'] {
		width: auto;
		padding: 0 0.6rem;
		font-weight: 500;
	}
</style>

<svelte:window on:keydown={keydown} />

<div class="keyboard">
	{#each ['1234567890⌫', 'QWERTYUIOP', 'ASDFGHJKL', 'ZXCVBNM'] as row}
		<div class="row">
			{#each row as letter}
				{#if letter == '⏎'}
					<button
						type="button"
						on:mousedown|preventDefault={pressed}
						on:touchstart|preventDefault={pressed}
						on:click|preventDefault={autoblur}
						data-key="enter"
						aria-label="enter"
					>
						Enter
					</button>
				{:else if letter == '⌫'}
					<button
						type="button"
						on:mousedown|preventDefault={pressed}
						on:touchstart|preventDefault={pressed}
						on:click|preventDefault={autoblur}
						data-key="backspace"
						aria-label="backspace"
					>
						{letter}
					</button>
				{:else}
					<button
						type="button"
						on:mousedown|preventDefault={pressed}
						on:touchstart|preventDefault={pressed}
						on:click|preventDefault={autoblur}
						data-key={letter}
						aria-label={letter}
					>
						{letter}
					</button>
				{/if}
			{/each}
		</div>
	{/each}
</div>