<script>

  import { fade } from 'svelte/transition';
  
  import { success } from '../lib/stores.js';

  export let size = 25;
  export let speed = 750;
  export let color = 'var(--accentColor)';
  export let thickness = 2;
  export let gap = 40;
  export let radius = 10;
  export let tick = false;

  $: if ($success) {
    color = 'var(--successColor)';
    gap = 0;
    setTimeout(() => {
      tick = true;
    }, 400);
  }

  let dash;
  $: dash = 2 * Math.PI * radius * (100 - gap) / 100

</script>

<style>
  .azn_spinner {
    display: grid;
    place-content: center;
  }
  .azn_spinner_svg {
    will-change: transform;
    position: static;
    transition-property: transform;
    animation-name: spinner-spin;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    backface-visibility: hidden;
  }
  .azn_spinner_svg.tick {
    animation-name: none;
  }
  @keyframes spinner-spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
  }
  circle {
    fill: none;
  }
  .success circle {
    transition: stroke 0.8s ease-in-out, stroke-dasharray 0.4s ease-in-out;    
  }
</style>

<p class="azn_spinner" style="color:{color}">
  <svg
    height="{size}"
    width="{size}"
    style="animation-duration:{speed}ms;"
    class="azn_spinner_svg"
    class:success
    class:tick
    viewbox="0 0 32 32"
  >
    <circle
      role="presentation"
      cx="16"
      cy="16"
      r="{radius}"
      stroke="{color}"
      fill="none"
      stroke-width="{thickness}"
      stroke-dasharray="{dash},100"
      stroke-linecap="round"
    />
    {#if tick}
      <polygon in:fade|local={{duration:600}} points="19.97,12.47 14.68,17.76 12.03,15.12 11.15,16 14.68,19.53 20.85,13.36"/>
    {/if}
  </svg>
</p>
