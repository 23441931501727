<script>

	import { onMount } from 'svelte';

	import Modal from './Modal.svelte';

	import { modal, bigError } from '../lib/stores.js';

	export let mounted = true;

	onMount(() => {

		let opts;

		if (mounted) {
			opts = [
				{
					name: "Dismiss",
					display: {
						mini: true
					},
					f: () => { $bigError = null },
					class: 'ghost'
				},
			];
		}

		$modal = {
			message: 'Sorry, there was a problem.',
			details: [$bigError],
			opts: opts
		};

	});


</script>

<Modal/>