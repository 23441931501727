<script>
	export let wide = true;
	export let center = true;
</script>

<style>
	div.buttonGroup {
		padding-top: 2rem;
		text-align: left;
	}
	div.buttonGroup.center {
		text-align: center;
	}
	div.buttonGroup > div {
		display: inline-flex;
		flex-wrap: wrap;
		gap: 1rem 2rem;
		max-width: 100%;
	}
	div.buttonGroup.wide > div {
		display: flex;
	}
	div.buttonGroup :global(button) {
		flex: 1 1 calc(50% - 1rem);
	}

	@media (max-width: 640px) {
		div.buttonGroup > div {
			gap: 1rem;
		}
		div.buttonGroup :global(button) {
			flex: 1 1 calc(25% - 1rem);
		}
	}
</style>

<div class="buttonGroup" class:wide class:center>
	<div>
		<slot/>
	</div>
</div>