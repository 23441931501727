<script>

	import { v4 as uuid } from 'uuid';

	export let ref = undefined;
	export let value = false;
	export let inFocus = false;
	export let disabled = false;

	if (!ref) ref = 'field-' + uuid();

</script>

<style>

	.onoffswitch {
		position: relative;
		width: 3.6em;
		display: inline-block;
		font-size: 80%;
		top: 1px;
	}

	.onoffswitch .onoffswitch-label {
		display: block;
		overflow: hidden;
		cursor: pointer;
		border-radius: 20px;
		margin: 0;
	}

	/*.inFocus .onoffswitch .onoffswitch-label {
		box-shadow: 0 0 0 1px var(--bgColor), 0 0 0 2px var(--blend-40);
	}*/

	.onoffswitch .onoffswitch-inner {
		width: 200%;
		margin-left: -100%;
		transition: margin 0.15s ease-in-out;
	}

	.onoffswitch .onoffswitch-inner:before,
	.onoffswitch .onoffswitch-inner:after {
		float: left;
		width: 50%;
		height: 2em;
		padding: 0;
		color: hsl(0, 0%, 100%);
		font-weight: normal;
		box-sizing: border-box;
	}

	.onoffswitch .onoffswitch-inner:before {
		content: "";
		background-color: var(--successColor);
	}

	.onoffswitch .onoffswitch-inner:after {
		content: "";
		background-color: var(--blend-40);
	}

	.onoffswitch .onoffswitch-switch {
		width: 2em;
		height: 2em;
		margin: 0;
		background: hsl(0, 0%, 100%);
		border-radius: 50%;
		position: absolute;
		top: 0;
		right: 1.6em;
		transition: right 0.15s ease-in-out;
		transform: scale(0.85);
	}

	.toggle-group {
		position: relative;
		display: inline-block;
		top: 50%;
	}

	.toggle-group input[type=checkbox] {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: 100;
		opacity: 0;
	}

	.toggle-group input[type=checkbox]:checked ~ .onoffswitch .onoffswitch-label .onoffswitch-inner {
		margin-left: 0;
	}

	.toggle-group input[type=checkbox]:checked ~ .onoffswitch .onoffswitch-label .onoffswitch-switch {
		right: 0;
	}

</style>

<div class="toggle-group" class:inFocus>
	<input
		type="checkbox"
		id={ref}
		bind:checked={value}
		on:focus={() => {inFocus = true}}
		on:blur={() => {inFocus = false}}
		{disabled}
	>
	<div class="onoffswitch pull-right" aria-hidden="true">
		<div class="onoffswitch-label">
			<div class="onoffswitch-inner"></div>
			<div class="onoffswitch-switch"></div>
		</div>
	</div>
</div>