<script>

	// Largely based on https://github.com/PeerPiper/qrcode-scanner-svelte

	import { onMount, onDestroy } from 'svelte';
	import { fade } from 'svelte/transition';

	import jsQR from 'jsqr';

	import ScannerBorders from './ScannerBorders.svelte';
	import UserMedia from './UserMedia.svelte';
		
	import { qrStream, qrError, qrStatus } from '../lib/stores.js';

	export let result = null;

	let video = null;
	let canvas = null;
	let stopMediaStream, startMediaStream;
	let useUserMedia;
	let mounted = false;

	onMount(async () => {
		setTimeout(() => {
		   ({ stopMediaStream, startMediaStream } = useUserMedia());
		   mounted = true;
		});
	});

	onDestroy(() => {
		stopMediaStream();
	});

	$: active = (mounted && !result);

	const onSuccessfulScan = (data) => {
	    result = data;
	    // console.log(result);
	};

	const startCapturing = () => {

	    // console.log('Starting capture');

	    if (canvas === null || canvas === null || video === null || video === null) {
	        console.log('QR: Can’t get canvas/video');
	        return;
	    }

	    const context = canvas.getContext('2d', { willReadFrequently: true });

	    if (context === null) {
	        console.log('QR: Can’t get canvas context');
	        return;
	    }

	    const { width, height } = canvas;

	    context.drawImage(video, 0, 0, width, height);

	    const imageData = context.getImageData(0, 0, width, height);
	    const qrCode = jsQR(imageData.data, width, height);

	    if (qrCode === null) {
	        // console.log('problem');
	        setTimeout(startCapturing, 750);
	    } else {
	        onSuccessfulScan(qrCode.data);
	        stopMediaStream();
	        video.srcObject = null;
	    }

	};

	const handleCanPlay = () => {

	    // console.log('canplay');

	    if (canvas === null || canvas === null || video === null || video === null) {
	        return;
	    }

	    canvas.width = video.videoWidth;
	    canvas.height = video.videoHeight;

	    if ($qrError !== null) {
	        // TODO: show dialog to user with an error
	    } else {
	        startCapturing();
	    }

	};

	$: if ($qrStatus === 'resolved' && video !== null && $qrStream) {
	    // console.log('Resolve, stream');
	    video.srcObject = $qrStream;
	    video.play().catch(console.error);
	}

	$: if (active && $qrStatus === 'stopped') {
	    startMediaStream();
	}

</script>

<style>
	.scanner {
		width: 100%;
/*		max-width: min(500px, calc(100dvh - 28rem));*/
		max-width: min(26rem, 80vw);
		opacity: 0;
		transition: opacity 0.3s ease;
		overflow: hidden;
		border-radius: 3px;
/*		box-shadow: 0 1rem 2rem rgba(0,0,0,0.6);*/
		aspect-ratio: 1/1;
		position: relative;
		background: #000;
		border-radius: 1rem;
	}

	.scanner.active {
		opacity: 1;
	}

	/*.scanner__aspect-ratio-container {
		position: relative;
		overflow: hidden;
		padding-bottom: 100%;
		border-radius: 3px;
		box-shadow: 0 1rem 2rem rgba(0,0,0,0.6);
	}*/

	.scanner__video {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius: inherit;
		outline: none;
		object-fit: cover;
	}

	.scanner__canvas {
		display: none;
	}
</style>

<UserMedia bind:useUserMedia />

{#if mounted}
	<div class="scanner" class:active in:fade={{ duration: 200 }}>
		<!-- <div class="scanner__aspect-ratio-container"> -->
			<canvas bind:this={canvas} class="scanner__canvas" />
			<!-- svelte-ignore a11y-media-has-caption -->
			<video bind:this={video} on:canplay={handleCanPlay} class="scanner__video" playsinline muted/>
			<ScannerBorders />
		<!-- </div> -->
	</div>
{/if}
