import './globals.css';
import App from './App.svelte';

import * as Sentry from "@sentry/svelte";
import { BrowserTracing } from "@sentry/tracing";

if (import.meta.env.MODE != 'development') {
  Sentry.init({
    dsn: "https://645a4f33259046ada524597b0d6a14b4@o4504492294995968.ingest.sentry.io/4505487924920320",
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

const app = new App({
  target: document.getElementById('app'),
})

export default app
