<script>

	// Buttons for the attendee details view in staffed (event) mode

	import { onDestroy } from 'svelte';

	import ButtonGroup from '../ui/ButtonGroup.svelte';
	import Button from '../ui/Button.svelte';
	import BadgeTemplate from './BadgeTemplate.svelte';

	import {
		screen,
		attendee,
		bigError,
		busy,
		event,
		settings,
		offline,
		modal,
		showOfflineBadgeModal
	} from '../lib/stores.js';

	import { postServerData } from '../lib/prelude.js';
	import { db } from "../lib/db.js";
	import { showSuccess } from "../lib/success.js";
	import { isDesktop } from '../lib/device.js';
	import { componentWindow } from '../lib/componentWindow.js';

	export let checkin; // function
	export let already = false;
	export let editPanel = false;

	let badgeWindow = new componentWindow();

	onDestroy(() => {
		badgeWindow.destroy();
	});

	function initUndoCheckin() {

		$modal = {
			message: 'Are you sure you want to undo check-in for this attendee?',
			details: [],
			opts: [
				{
					name: "Undo check-in",
					display: {
						red: true,
						mini: true
					},
					f: doUndoCheckin
				},
				{
					name: "Cancel",
					display: {
						ghost: true,
						mini: true
					}
				},
			]
		};

	}

	async function doUndoCheckin() {

		let checkedin = true;

		$busy = true;

		if ($offline) {

			const dt = new Date().toISOString().replace('Z',''); // UTC

			db.offline.add({
				action: 'undoCheckin',
				mode: $settings.mode,
				dt: dt,
				event: $event.ref,
				attendee: $attendee.ref
			});

			checkedin = false;

		} else {

			const checkinResponse = await postServerData('/checkin/undo', {
				attendeeRef: $attendee.ref
			});

			if (!$bigError) {
				if (checkinResponse.error) {
					$bigError = 'We were unable to undo check-in for this attendee.';
					$busy = false;
				} else {
					checkedin = false;
				}
			}

		}

		if (!checkedin) {
			showSuccess(async function(){
				await db.attendees.update($attendee.ref, {
					status: 'registered'
				});
				$attendee = await db.attendees.get($attendee.ref);
				// $attendee.status = 'registered';
				// already = false;
			});
		}

	}

	async function reprintBadge() {

		$busy = true;

		const printResponse = await postServerData('/checkin/print', {
			attendeeRef: $attendee.ref,
			templateRef: $attendee.badgeTemplate,
			printer: $settings.printer
		});

		if (!$bigError) {
			if (printResponse.error) {
				$bigError = 'We were unable to print a new badge for this attendee.';
				$busy = false;
			} else {
				showSuccess();
			}
		}

	}

	async function openPDF() {

		$busy = true;

		const printResponse = await postServerData('/checkin/print', {
			attendeeRef: $attendee.ref,
			templateRef: $attendee.badgeTemplate
		});

		if (!$bigError) {
			$busy = false;
			if (printResponse.pdf) {
				window.open(printResponse.pdf);
			}
		}

	}

	async function openBadgeTemplate() {

		if ($showOfflineBadgeModal) {

			let modalDetails = [];

			if ($settings.printer) {
				modalDetails.push('This means that automatic badge printing from check-in devices is not possible.');
			}

			modalDetails.push('A new window will open with a representation of the attendee badge, ready to print.');
			modalDetails.push('Your printer must be connected to this computer.');
			modalDetails.push('Make sure the print window is configured to use the correct printer and paper size.');
			modalDetails.push('You should also switch off headers and footers, and allow background graphics.');

			$modal = {
				message: 'You’re currently offline.',
				details: modalDetails,
				opts: [
					{
						name: "OK",
						display: {
							green: true,
							mini: true
						},
						f: () => {
							$showOfflineBadgeModal = false;
							openBadgeTemplate();
						}
					}
				]
			};

		} else {

			if (badgeWindow && badgeWindow.isOpened) {
				badgeWindow.focus();
				return;
			}

			await badgeWindow.attachComponent(BadgeTemplate, {
				props: {
					w: badgeWindow.window
				}
			});

			badgeWindow.focus();

			setTimeout(() => {
				badgeWindow.print();
			}, 400);

		}

	}

</script>

<style>
</style>

<ButtonGroup>
	{#if $settings.staffed}
		{#if already}
			{#if $offline}
				{#if isDesktop()}
					<Button ghost={true} on:click={openBadgeTemplate} label="Print badge">
						<svg viewBox="0 0 80 80"><path d="M65 25h-10v-8c0-2.2-1.8-4-4-4h-22c-2.2 0-4 1.8-4 4v8h-10c-2.2 0-4 1.8-4 4v22c0 2.2 1.8 4 4 4h10v8c0 2.2 1.8 4 4 4h22c2.2 0 4-1.8 4-4v-8h10c2.2 0 4-1.8 4-4v-22c0-2.2-1.8-4-4-4zm-36-6c0-1.1.9-2 2-2h18c1.1 0 2 .9 2 2v6h-22v-6zm22 42c0 1.1-.9 2-2 2h-18c-1.1 0-2-.9-2-2v-15h22v15zm14-12c0 1.1-.9 2-2 2h-8v-5h1.2c1.1 0 2-.9 2-2s-.9-2-2-2h-32.4c-1.1 0-2 .9-2 2s.9 2 2 2h1.2v5h-8c-1.1 0-2-.9-2-2v-18c0-1.1.9-2 2-2h46c1.1 0 2 .9 2 2v18z"/></svg>
					</Button>
				{/if}
			{:else}
				{#if $settings.printer}
					<Button ghost={true} on:click={reprintBadge} label="Reprint badge">
						<svg viewBox="0 0 80 80"><path d="M65 25h-10v-8c0-2.2-1.8-4-4-4h-22c-2.2 0-4 1.8-4 4v8h-10c-2.2 0-4 1.8-4 4v22c0 2.2 1.8 4 4 4h10v8c0 2.2 1.8 4 4 4h22c2.2 0 4-1.8 4-4v-8h10c2.2 0 4-1.8 4-4v-22c0-2.2-1.8-4-4-4zm-36-6c0-1.1.9-2 2-2h18c1.1 0 2 .9 2 2v6h-22v-6zm22 42c0 1.1-.9 2-2 2h-18c-1.1 0-2-.9-2-2v-15h22v15zm14-12c0 1.1-.9 2-2 2h-8v-5h1.2c1.1 0 2-.9 2-2s-.9-2-2-2h-32.4c-1.1 0-2 .9-2 2s.9 2 2 2h1.2v5h-8c-1.1 0-2-.9-2-2v-18c0-1.1.9-2 2-2h46c1.1 0 2 .9 2 2v18z"/></svg>
					</Button>
				{:else if (isDesktop())}
					<Button ghost={true} on:click={openPDF} label="Open badge PDF">
						<svg viewBox="0 0 80 80"><path d="M57.8 26.1l-10.9-9.9c-.8-.8-1.8-1.2-2.9-1.2h-19c-2.2 0-4 1.8-4 4v42c0 2.2 1.8 4 4 4h30c2.2 0 4-1.8 4-4v-32c0-1-.4-2.1-1.2-2.9zm-4.8 34.9h-26c-1.1 0-2-.9-2-2v-38c0-1.1.9-2 2-2h13v10c0 2.2 1.8 4 4 4h11v26c0 1.1-.9 2-2 2zm2-32h-11v-10l11 10zM28.7 40.1h3.3c2 0 3.1 1.2 3.1 2.9s-1.1 2.9-3.1 2.9h-1.7v2.9h-1.6v-8.7zm3.1 4.5c1.2 0 1.7-.7 1.7-1.6 0-.9-.5-1.6-1.7-1.6h-1.4v3.2h1.4zM36.7 48.8v-8.7h3c2.6 0 4.2 1.6 4.2 4.4 0 2.7-1.6 4.4-4.2 4.4h-3zm2.9-1.3c1.8 0 2.7-1 2.7-3s-.9-3-2.7-3h-1.3v6h1.3zM45.8 40.1h5.6v1.3h-4v2.4h3.6v1.3h-3.6v3.7h-1.6v-8.7z"/></svg>
					</Button>
				{/if}
			{/if}
			<Button ghost={true} on:click={initUndoCheckin} label="Undo check-in">
				<svg viewBox="0 0 80 80"><path d="M21.1 40c0-11.53 9.38-20.91 20.91-20.91s20.91 9.38 20.91 20.91-9.38 20.91-20.91 20.91v4c13.74 0 24.91-11.18 24.91-24.91s-11.17-24.91-24.91-24.91-24.91 11.17-24.91 24.91h-6.02l8.34 14.32 8.35-14.32h-6.67z"/></svg>
			</Button>
		{:else}
			<Button wide={true} on:click={checkin} label="Check in">
				<svg viewBox="0 0 80 80"><path d="M28.8 59.6l-12.6-12.6 4.3-4.2 8.3 8.3 30.7-30.7 4.3 4.2z"/></svg>
			</Button>
		{/if}
	{/if}
	{#if $settings.allowEdits && ($settings.staffed || $settings.printer)}
		<Button ghost={true} on:click={() => { editPanel = true }} label="Edit badge">
			<svg viewBox="0 0 80 80"><path d="M62 63c0 1.1-.9 2-2 2h-46c-1.1 0-2-.9-2-2v-36c0-1.1.9-2 2-2h33l4-4h-10v-6c0-2.2-1.8-4-4-4s-4 1.8-4 4v6h-21c-2.2 0-4 1.8-4 4v40c0 2.2 1.8 4 4 4h50c2.2 0 4-1.8 4-4v-25l-4 4v19zM45.7 54.6l-12.7 3.3c-.6.1-1.1 0-1.5-.4-.4-.4-.6-1-.4-1.5l3.3-12.6 11.3 11.2zM58.3 19.4c3.1-3.1 8.2-3.1 11.3 0 3.1 3.1 3.1 8.2 0 11.3l-11.3-11.3zM66.7 33.6l-11.3-11.3-18.2 18.1 4.3 4.3 15.3-15.4 2.9 2.8-15.4 15.4 4.3 4.2z"/></svg>
		</Button>
	{/if}
	<Button ghost={true} on:click={() => { $attendee = null; $screen = null; }} label="Cancel">
		<svg viewBox="0 0 80 80"><path d="M24.4 20.2l-4.2 4.2 15.6 15.6-15.6 15.6 4.2 4.2 15.6-15.6 15.5 15.6 4.3-4.3-15.6-15.5 15.6-15.6-4.2-4.2-15.6 15.6z"/></svg>
	</Button>
</ButtonGroup>