<script>

	// Would isDecimal be better (allows decimal point locale) ..?
	import isNumeric from 'validator/es/lib/isNumeric';

	export let ref;
	export let value = '';
	export let err;
	export let inputmode = 'decimal';
	export let inFocus;
	export let placeholder;
	export let maxlength;
	export let disabled;

	function handleFocus() {
		inFocus = true;
	}

	function handleBlur() {
		inFocus = false;
	}

	function handleInput() {
		let e = null;
		if (value) {
			if (!isNumeric(value)) {
				e = 'Please enter a valid number.'; // TODO: i18n
			}
		}
		err = e;
	}

</script>

<style>
	input {
		box-sizing: border-box;
		width: 100%;
		max-width: 30rem;
		border: 1px solid var(--blend-40);
		background: var(--blend-05);
		color: var(--textColor);
		padding: 0.8rem 1rem;
		line-height: 1;
		font-size: 1rem;
		border-radius: 3px;
	}
	input.err {
		border-color: var(--errorColor);
	}
	input:focus {
		outline: none;
		border-color: var(--textColor);
	}
	input::-webkit-input-placeholder {
		color: var(--blend-40);
	}
	input::-moz-placeholder {
		color: var(--blend-40);
	}
</style>

<input
	type="text"
	id={ref}
	bind:value={value}
	{inputmode}
	{maxlength}
	autocomplete="off"
	class:err
	on:focus={handleFocus}
	on:blur={handleBlur}
	on:input={handleInput}
	{placeholder}
	{disabled}
/>
