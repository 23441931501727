<script>

	import { onMount } from 'svelte';

	import { attendee, templateHashtags, socialMediaServiceOpts } from '../../lib/stores.js';

	import isURL from 'validator/es/lib/isURL';

	export let ref;
	export let value = '';
	export let err;
	export let inputmode = 'url';
	export let inFocus;
	export let placeholder;
	export let maxlength;
	export let disabled;

	let socialMediaService = null;

	onMount(async () => {
		if ($attendee && $attendee.badgeTemplate && $templateHashtags && $templateHashtags[$attendee.badgeTemplate]) {
			for (const h of $templateHashtags[$attendee.badgeTemplate]) {
				if (h.id == ref) {
					if (h.component && h.component.service) {
						for (const s of $socialMediaServiceOpts) {
							if (s.name == h.component.service) {
								socialMediaService = s;
								break;
							}
						}
					}
					break;
				}
			}
		}
	});

	function handleFocus() {
		inFocus = true;
	}

	function handleBlur() {
		inFocus = false;
	}

	function handleInput() {
		let e = null;
		if (value) {
			if (socialMediaService) {
				if (!isURL(value, { protocols: ['http','https'], host_whitelist: socialMediaService.domains })) {
					e = 'Please enter a valid ' + socialMediaService.name + ' URL.'; // TODO: i18n
				}
			} else {
				if (!isURL(value, { protocols: ['http','https'] })) {
					e = 'Please enter a valid URL.'; // TODO: i18n
				}
			}
		}
		err = e;
	}

</script>

<style>
	input {
		box-sizing: border-box;
		width: 100%;
		max-width: 30rem;
		border: 1px solid var(--blend-40);
		background: var(--blend-05);
		color: var(--textColor);
		padding: 0.8rem 1rem;
		line-height: 1;
		font-size: 1rem;
		border-radius: 3px;
	}
	input.err {
		border-color: var(--errorColor);
	}
	input:focus {
		outline: none;
		border-color: var(--textColor);
	}
	input::-webkit-input-placeholder {
		color: var(--blend-40);
	}
	input::-moz-placeholder {
		color: var(--blend-40);
	}
</style>

<input
	type="text"
	id={ref}
	bind:value={value}
	{inputmode}
	{maxlength}
	autocomplete="off"
	class:err
	on:focus={handleFocus}
	on:blur={handleBlur}
	on:input={handleInput}
	{placeholder}
	{disabled}
/>