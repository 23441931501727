<script>

	export let ref;
	export let value;
	export let label;
	export let disabled;

	if (value == undefined || value == null) {
		value = false;
	}

</script>

<style>

	p {
		position: relative;
		padding-left: 1.6em;
	}

	.checkbox {
		width: 1.2em;
		height: 1.2em;
		position: absolute;
		top: 0.2em;
		left: 0;
		overflow: visible;
	}

	.checkbox input {
		position: absolute;
		inset: 0;
		padding: 0;
		margin: 0;
		opacity: 0;
		z-index: 2;
	}

	.checkbox svg {
		position: absolute;
		inset: 0;
		opacity: 1;
		z-index: 1;
	}

	.checkbox path {
		fill: currentColor;
		transition: opacity 0.2s ease-in-out;
	}

	.checkbox path.on-tick {
		fill: var(--bgColor);
	}

	.checkbox input + svg .on {
		opacity: 0;
	}

	.checkbox input:checked + svg .on {
		opacity: 1;
	}

	label {
		font-size: 1rem;
	}

</style>

<p>
	<span class="checkbox">	
		<input
			type="checkbox"
			id="{ref}"
			bind:checked={value}
			{disabled}
		/>
		<svg viewBox="0 0 50 50"><g class="on"><path class="on-bg" d="M0 4c0-2.21 1.79-4 4-4h42c2.21 0 4 1.79 4 4v41.99c0 2.21-1.79 4-4 4h-42c-2.21 0-4-1.79-4-4v-41.99z"/><path class="on-tick" d="M20.33 38.22l-12.21-12.21 4.24-4.24 7.97 7.97 17.2-17.2 4.24 4.25z"/></g><path class="off" d="M0 4c0-2.21 1.79-4 4-4h42c2.21 0 4 1.79 4 4v41.99c0 2.21-1.79 4-4 4h-42c-2.21 0-4-1.79-4-4v-41.99zm44 42c1.1 0 2-.9 2-2v-38.01c0-1.1-.9-2-2-2h-38c-1.1 0-2 .9-2 2v38.01c0 1.1.9 2 2 2h38z"/></svg>
	</span>
	<label for={ref}>{label}</label>
</p>