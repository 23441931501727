<script>

	import { onMount } from 'svelte';

	import UserMedia from './UserMedia.svelte';
		
	import { qrStatus } from '../lib/stores.js';

	let useUserMedia;
	let stopMediaStream, startMediaStream;

	onMount(async () => {
	    ({ stopMediaStream, startMediaStream } = useUserMedia());
	    startMediaStream();
	});

	$: if ($qrStatus == 'resolved') {
		if (stopMediaStream) {
			stopMediaStream();
		}
	}

</script>

<UserMedia bind:useUserMedia />