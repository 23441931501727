<script>

	import { onMount } from 'svelte';

	import cssVars from 'svelte-css-vars';

	import { event } from '../../lib/stores.js';

	export let c;

	let cVars = {};
	let mounted = false;

	const flexAlignments = {
		left: 'flex-start',
		center: 'center',
		right: 'flex-end'
	};

	const percentages = {
		'left': '0%',
		'center': '50%',
		'right': '100%',
		'flex-start': '0%',
		'flex-end': '100%'
	};

	const cdnurl = 'https://cdn.attendzen.io';

	onMount(() => {
		cVars.bgColor = c.bgColor ? $event.theme.colors[c.bgColor] : 'transparent';
		cVars.crop = c.crop;
		cVars.alignment = c.alignment;
		cVars.flexAlignment = flexAlignments[c.alignment];
		cVars.verticalAlignment = c.verticalAlignment;
		cVars.objectAlignment = `${percentages[c.alignment]} ${percentages[c.verticalAlignment]}`;
		cVars.aspectRatio = c.image?.aspectRatio;
		cVars.focalPoint = `${c.image?.focalPointX} ${c.image?.focalPointY}`;
		if (c.image?.filename && (c.presentation == 'tinted')) {
			cVars.tintColor = $event.theme.colors[c.tintColor];
			if (c.image.format == 'svg') {
				cVars.mask = `url('${cdnurl}/${$event.accountRef}/mask_${c.image.filename.replace(/\.svg$/,'.png')}')`;
			} else {
				cVars.mask = `url('${cdnurl}/${$event.accountRef}/mask_${c.image.filename.replace(/\.jpe?g$/,'.png')}')`;
			}
		}
		mounted = true;
	});

</script>

{#if mounted}
	<section class="component { c.component } { c.presentation } { c.width }" class:padded={c.padding} use:cssVars={cVars}>
		<div>
			<div class="{c.image?.type}">
				<div>
					<span>
						{#if c.image?.filename && (c.presentation == 'tinted') && (c.image?.format == 'svg')}
							<img crossorigin="anonymous" src="{cdnurl}/{$event.accountRef}/mask_{c.image.filename.replace(/\.svg$/,'.png')}" alt="" />
						{:else if c.image?.filename && (c.presentation == 'tinted')}
							<img crossorigin="anonymous" src="{cdnurl}/{$event.accountRef}/mask_{c.image.filename.replace(/\.jpe?g$/,'.png')}" alt="" />
						{:else if c.image?.filename && (c.image?.format == 'svg')}
							<img crossorigin="anonymous" src="{ cdnurl }/{ $event.accountRef }/{ c.image.filename }" alt="" />
						{:else if c.image?.filename}
							<img crossorigin="anonymous" src="{ cdnurl }/{ $event.accountRef }/{ c.image.type == 'logo' ? 'trim' : 'max' }_{ c.image.filename }" alt="" />
						{:else if c.image?.unsplash}
							<img crossorigin="anonymous" src="{ c.image.unsplash.url }&amp;w=1200" alt="" />
						{/if}
					</span>
				</div>
			</div>
		</div>
	</section>
{/if}