import { persist, createLocalStorage, createSessionStorage } from "@macfja/svelte-persistent-store";
import { writable } from 'svelte/store';

export const displayLang = writable('EN');

export const csrfToken = persist(writable(''), createLocalStorage(), 'csrfToken');
export const unlockCode = persist(writable(''), createLocalStorage(), 'unlockCode');
export const deviceRef = persist(writable(''), createLocalStorage(), 'deviceRef');
export const deviceName = persist(writable(''), createLocalStorage(), 'deviceName');

export const busy = writable(false);
export const bigError = writable(undefined);
export const success = writable(false);
export const modal = writable(undefined);
export const message = writable(null);

export const offline = writable(false);
export const lastOnline = writable(null);
export const showOfflineBadgeModal = writable(true);

export const appElement = writable(undefined);

export const showSettings = writable(false);
export const unbranded = writable(false);
export const screen = writable(undefined);

export const showRegform = persist(writable(false), createSessionStorage(), 'showRegform');

export const settings = persist(writable(null), createLocalStorage(), 'settings');
export const event = persist(writable(null), createLocalStorage(), 'event');
export const vips = persist(writable(null), createLocalStorage(), 'vips');
export const splash = persist(writable(null), createLocalStorage(), 'splash');
export const templateHashtags = persist(writable(null), createLocalStorage(), 'templateHashtags');
export const templates = persist(writable(null), createLocalStorage(), 'templates');

export const countryOpts = persist(writable(''), createLocalStorage(), 'countryOpts');
export const socialMediaServiceOpts = persist(writable(''), createLocalStorage(), 'socialMediaServiceOpts');
export const crmFields = persist(writable(null), createLocalStorage(), 'crmFields');

export const logoSize = writable('small');

export const attendee = writable(null);

export const qrStream = writable(null);
export const qrError = writable(null);
export const qrStatus = writable(null);

export const printers = writable([]);

export const isNarrowScreen = writable(false);
