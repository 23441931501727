<script>

	import { onMount } from 'svelte';

	import cssVars from 'svelte-css-vars';

	import VCard from 'vcard-creator';
	import QRCode from 'qrcode';

	import { event, attendee } from '../../lib/stores.js';

	export let c;

	let cVars = {};
	let mounted = false;

	let vcard = '';
	let qr = '';

	onMount(() => {

		cVars.bgColor = c.bgColor ? $event.theme.colors[c.bgColor] : 'transparent';

		if (c.presentation == 'vcard') {

			vcard = new VCard()
				.addName($attendee.l, $attendee.f)
				.addCompany($attendee.o)
				.addEmail($attendee.e)
				.addUID(`urn:uuid:${$attendee.ref}`)
				.toString();

			// console.log(vcard);

			QRCode.toDataURL(vcard, { errorCorrectionLevel: 'L' }, function (err, url) {
				qr = url;
			});

		} else {

			// ref

			QRCode.toDataURL($attendee.ref, { errorCorrectionLevel: 'L' }, function (err, url) {
				qr = url;
			});

		}

		mounted = true;

	});

</script>

{#if mounted}
	<section class="component { c.component }" use:cssVars={cVars}>
		<div>
			<p><img src={qr} alt=""/></p>
		</div>
	</section>
{/if}