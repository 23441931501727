<script>

	import { onMount} from 'svelte';

	import dayjs from 'dayjs/esm/index.js';

	import DatePicker from '../DatePicker.svelte';

	export let value = '';

	let mounted = false;
	let selected = new Date();
	let formatted = '';
	let openPicker = false;
	let store;
	let format = 'D MMMM YYYY';

	onMount(() => {
		selected = value ? dayjs(value).toDate() : new Date();
		formatted = value ? dayjs(value).format(format) : '';
		mounted = true;
	});

	function setValue() {
		if (mounted && selected) {
			if ($store?.hasChosen) {
				value = selected;
				openPicker = false;
			}
		}
	}

	$: if (selected) {
		setValue();
	}

</script>

<style>
	div {
		width: 100%;
		position: relative;
	}
	button {
		box-sizing: border-box;
		width: 100%;
		max-width: 30rem;
		border: 1px solid var(--blend-40);
		background: var(--blend-05);
		color: var(--textColor);
		padding: 0.8rem 1rem;
		line-height: 1;
		font-size: 1rem;
		border-radius: 3px;
		text-align: left;
		min-height: calc(2.6rem + 2px);
	}
</style>

<div>	
	<button type="button" on:click={() => {openPicker = true}}>
		{formatted}
	</button>
</div>

{#if openPicker}
	<DatePicker bind:store bind:selected bind:formatted bind:active={openPicker} {format} />
{/if}