<script>

    // Based on https://github.com/PeerPiper/qrcode-scanner-svelte

    import { qrStream, qrError, qrStatus, settings } from '../lib/stores.js';

    const isMediaStream = (candidate) => candidate !== null && 'getTracks' in candidate;

    let facingMode = $settings.staffed ? 'environment' : 'user';

    function setStatus(params) {
        // console.log(`Setting status ${params}`);
        $qrStatus = params;
    }

    export const useUserMedia = () => {

        $qrStream = null;
        $qrError = null;
        $qrStatus = 'stopped';

        function setError(params) {
            // console.log('Setting erro');
            $qrError = params;
        }

        function setStream(params) {
            // console.log(`Setting stream`, { params });
            $qrStream = params;
        }

        const startMediaStream = () => {
            setStatus('pending');
            navigator.mediaDevices.getUserMedia({
                audio: false,
                video: {
                    facingMode: facingMode
                }
            }).then((userStream) => {
                setStream(userStream);
                setStatus('resolved');
            }).catch((err) => {
                setError(err);
                setStatus('rejected');
            });
        };

        const stopMediaStream = () => {
            // console.log('stopping media stream');
            if (isMediaStream($qrStream)) {
                $qrStream.getTracks().forEach((track) => {
                    track.stop();
                    $qrStream.removeTrack(track);
                });
                setStatus('stopped');
            }
        };

        return { stopMediaStream, startMediaStream };

    };

</script>
