<script>

	import { onMount } from 'svelte';

	import cssVars from 'svelte-css-vars';

	import { event } from '../../../lib/stores.js';

	export let c;
	export let logo;

	const cdnurl = 'https://cdn.attendzen.io';

	let vars = {};
	let mounted = false;

	let maskImg;

	onMount(() => {
		vars.aspectRatio = logo.aspectRatio;
		if (c.presentation == 'tinted') {
			if (logo.format == 'svg') {
				maskImg = `${cdnurl}/${$event.accountRef}/mask_${logo.filename.replace(/\.svg$/,'.png')}`;
			} else {
				maskImg = `${cdnurl}/${$event.accountRef}/mask_${logo.filename.replace(/\.jpe?g$/,'.png')}`;
			}
			vars.mask = `url('${maskImg}')`;
		}
		mounted = true;
	});

</script>

{#if mounted}
	<li use:cssVars={vars}>
		{#if c.presentation == 'tinted'}
			<img crossorigin="anonymous" src="{ maskImg }" alt=""/>
		{:else if logo.format == 'svg'}
			<img crossorigin="anonymous" src="{ cdnurl }/{$event.accountRef}/{ logo.filename }" alt=""/>
		{:else}
			<img crossorigin="anonymous" src="{ cdnurl }/{$event.accountRef}/trim_{ logo.filename }" alt=""/>
		{/if}
	</li>
{/if}
