<script>

	import { onDestroy } from 'svelte';
	import { fade, fly } from 'svelte/transition';

	import { InlineCalendar } from 'svelte-calendar';

	export let selected;
	export let formatted;
	export let store;
	export let format = 'DD MMM YY';
	export let active = true;

	const theme = {
		"calendar": {
			"width": "22rem",
			"maxWidth": "22rem",
			"legend": {
				"height": "34px"
			},
			"shadow": "none",
			"colors": {
				"text": {
					"primary": "var(--textColor)",
					"highlight": "var(--blend-x)"
				},
				"background": {
					"primary": "var(--blend-x)",
					"highlight": "var(--accentColor)",
					"hover": "transparent"
				},
				"border": "transparent"
			},
			"font": {
				"regular": "1rem",
				"large": "2rem"
			},
			"grid": {
				"disabledOpacity": "0.2",
				"outsiderOpacity": "0.9"
			}
		}
	};

	onDestroy(() => {
		store = undefined;
	});

</script>

<style>
	.outer {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: grid;
		place-content: center;
		background: var(--blend-x);
		z-index: 100;
	}
	.inner {
		padding: 2rem;
	}
</style>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<div class="outer" in:fade={{ duration: 200 }} on:click|stopPropagation={() => { active = false }}>
	<div class="inner" in:fly={{ y: 10, duration: 200, delay: 200 }} on:click|stopPropagation>
		<InlineCalendar
			bind:store
			bind:selected
			bind:formatted
			{format}
			{theme}
		/>
	</div>
</div>
