<script>

	import { onMount, onDestroy } from 'svelte';
	import { fade, fly } from 'svelte/transition';

	import { IdleSessionTimeout } from "idle-session-timeout";

	import ButtonGroup from './ui/ButtonGroup.svelte';
	import Button from './ui/Button.svelte';

	import { screen, message, attendee } from './lib/stores.js';

	let session = new IdleSessionTimeout(30 * 1000); // 30s

	onMount(() => {
		session.start();
	});

	onDestroy(() => {
		session.dispose();
	});

	function clear() {
		$message = null;
		$attendee = null;
		$screen = null;
	}
 
	session.onTimeOut = clear;

</script>

<style>
	.message {
		display: flex;
		flex-direction: column;
		height: 100%;
		box-sizing: border-box;
		padding-bottom: 2rem;
	}
	.display {
		max-width: 600px;
		margin: 0 auto;
		flex: 1 1 auto;
		overflow-y: auto;
		display: grid;
		place-content: center;
		padding-bottom: 2rem;
		font-size: clamp(1.4rem, 2rem, 4vw);
		color: var(--textColor);
		line-height: 1.4;
	}
	/*.display > div {
		margin-bottom: 2rem;
	}*/
	.display p + p {
		margin-top: 1.4rem;
	}

	/*.basicData {
		padding: 2rem 0 4rem 0;
		font-size: 4rem;
		color: var(--textColor);
		line-height: 1.1;
	}

	.basicData .o {
		display: block;
		font-size: 0.6em;
		line-height: 1.3;
		margin-top: 0.5rem;
	}*/
</style>

<div class="message" in:fly={{ duration: 200, y: 10, delay: 100 }}>

	<div class="display">

		<!-- <div class="basicData" bind:this={parent}>
			<p use:textfit={{parent, mode:"multi"}}>
				<strong>{$attendee.f} {$attendee.l}</strong>
				{#if $attendee.o}
					<span class="o">{$attendee.o}</span>
				{/if}
			</p>
		</div> -->

		<p><strong>{$message.message}</strong></p>
		{#if $message.details}
			{#each $message.details as d}
				{#if $message.htmlDetails}
					<p>{@html d}</p>
				{:else}
					<p>{d}</p>
				{/if}
			{/each}
		{/if}
	</div>

	<div in:fade={{ duration: 400, delay: 600 }}>
		<ButtonGroup wide={false}>
			<Button double={true} on:click={clear} label="OK"/>
		</ButtonGroup>
	</div>

</div>