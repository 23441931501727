<script>

	import { onMount } from 'svelte';

	import cssVars from 'svelte-css-vars';

	import Logo from './SponsorsGrid/Logo.svelte';

	import { event } from '../../lib/stores.js';

	export let c;

	let cVars = {};
	let mounted = false;

	onMount(() => {
		cVars.bgColor = c.bgColor ? $event.theme.colors[c.bgColor] : 'transparent';
		if (c.presentation == 'tinted') {
			cVars.tintColor = c.tintColor ? $event.theme.colors[c.tintColor] : $event.theme.colors['textColor'];
		}
		mounted = true;
	});

</script>

{#if mounted}
	<section class="component { c.component } { c.presentation } { c.size }" use:cssVars={cVars}>
		<div>
			<ul>
				{#if c.sponsors}
					{#each c.sponsors as sponsor}
						{#if sponsor.logo}
							<Logo logo={sponsor.logo} {c}/>
						{/if}
					{/each}
				{/if}
			</ul>
		</div>
	</section>
{/if}
