<script>

	import { onMount } from 'svelte';

	import cssVars from 'svelte-css-vars';

	import AttendeePhoto from './AttendeePhoto.svelte';
	import Image from './Image.svelte';
	import QRCode from './QRCode.svelte';
	import TextBlock from './TextBlock.svelte';

	import { event } from '../../lib/stores.js';

	export let c;

	let cVars = {};
	let mounted = false;

	const componentLookup = {
		'AttendeePhoto': AttendeePhoto,
		'Image': Image,
		'QRCode': QRCode,
		'TextBlock': TextBlock,
	};

	onMount(() => {
		cVars.ratio = c.ratio ? c.ratio : 50;
		mounted = true;
	});

</script>

{#if mounted}
	<section class="component { c.component }" use:cssVars={cVars}>
		<div>
			<svelte:component this={componentLookup[c.firstSection.component]} c={c.firstSection} />
			<svelte:component this={componentLookup[c.secondSection.component]} c={c.secondSection} />
		</div>
	</section>
{/if}