<script>

	import { onMount, onDestroy } from 'svelte';

	import { IdleSessionTimeout } from "idle-session-timeout";

	import Scanner from './QR/Scanner.svelte';
	import Search from './Search.svelte';

	import Panel from './ui/Panel.svelte';
	import ButtonGroup from './ui/ButtonGroup.svelte';
	import Button from './ui/Button.svelte';

	import { screen, settings, attendee, modal } from './lib/stores.js';
	import { db } from "./lib/db.js";

	let showSearch;
	let result;

	let session = new IdleSessionTimeout(20 * 1000); // 20s

	onMount(() => {
		session.start();
	});

	onDestroy(() => {
		session.dispose();
	});

	function clear() {
		$screen = null;
	}
 
	session.onTimeOut = clear;

	async function checkResult() {

		if (result.startsWith('BEGIN:VCARD')) {
			let vcard = result.replace(/\r\n  /gs, '');
			let regex = /UID\:urn\:uuid\:(.{36})/gs;
			const uuids = regex.exec(vcard);
			if (uuids.length) {
				result = uuids[1];
			}
		}

		let found = await db.attendees.get(result);

		if (found) {
			$attendee = found;
		} else {
			$modal = {
				message: 'No matching attendee.',
				details: [
					'This QR code doesn’t match an attendee for this event.'
				],
				opts: [
					{
						name: "Dismiss",
						display: {
							mini: true
						},
						f: () => {
							result = undefined;
						}
					},
				]
			};
		}
	}

	$: if (result) {
		checkResult();
	}

</script>

<style>
	.qr {
		display: flex;
		flex-direction: column;
		height: 100%;
		box-sizing: border-box;
		padding-bottom: 2rem;
	}
	.scanner {
		display: flex;
		justify-content: center;
		align-items: center;
		flex: 1 0 auto;
		padding-bottom: 2rem;
	}
</style>

<div class="qr">

	<div class="scanner">
		{#if !result && !showSearch}
			<Scanner bind:result/>
		{/if}
	</div>

	<ButtonGroup wide={false}>
		{#if $settings.search && !$settings.staffed}
			<Button ghost={true} on:click={() => { $screen = 'search' }} double={true} label="Search by name">
				<svg viewBox="0 0 80 80"><path d="M64.1 59.9l-15-15c2.2-3.1 3.6-6.9 3.6-11 0-10.4-8.4-18.8-18.8-18.8-10.5-.1-18.9 8.3-18.9 18.7s8.4 18.8 18.8 18.8c4.1 0 7.9-1.3 11-3.6l15 15c1.2 1.2 3.1 1.2 4.2 0s1.3-3 .1-4.1zm-45.1-26.1c0-8.1 6.7-14.8 14.8-14.8s14.8 6.7 14.8 14.8-6.6 14.9-14.8 14.9-14.8-6.7-14.8-14.9z"/></svg>
			</Button>
		{/if}
		<Button ghost={true} on:click={() => { $screen = undefined }} double={true} label="Cancel">
			<svg viewBox="0 0 80 80"><path d="M24.4 20.2l-4.2 4.2 15.6 15.6-15.6 15.6 4.2 4.2 15.6-15.6 15.5 15.6 4.3-4.3-15.6-15.5 15.6-15.6-4.2-4.2-15.6 15.6z"/></svg>
		</Button>
	</ButtonGroup>

</div>

{#if showSearch}
	<Panel on:close={() => { showSearch = false }}>
		<Search/>
	</Panel>
{/if}
