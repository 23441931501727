<script>

	import { onMount } from 'svelte';

	import cssVars from 'svelte-css-vars';

	import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';

	import { event, attendee, templateHashtags, countryOpts } from '../../lib/stores.js';
	import { showDate } from '../../lib/dt.js';

	export let c;

	let cVars = {};
	let mounted = false;

	const flexAlignments = {
		left: 'flex-start',
		center: 'center',
		right: 'flex-end'
	};

	let html = '';

	onMount(() => {

		// console.log({$attendee});
		// console.log({c});

		cVars.bgColor = c.bgColor ? $event.theme.colors[c.bgColor] : 'transparent';
		cVars.textColor = c.textColor ? $event.theme.colors[c.textColor] : $event.theme.colors['textColor'];
		cVars.linkColor = c.linkColor ? $event.theme.colors[c.linkColor] : $event.theme.colors['accent1'];
		cVars.headingColor = c.headingColor ? $event.theme.colors[c.headingColor] : $event.theme.colors['textColor'];
		cVars.alignment = c.alignment;
		cVars.flexAlignment = flexAlignments[c.alignment];
		cVars.verticalAlignment = c.verticalAlignment;

		let attendeeData = {};
		let fields = {};

		if ($attendee.badgeTemplate && $templateHashtags && $templateHashtags[$attendee.badgeTemplate]) {
			for (const f of $templateHashtags[$attendee.badgeTemplate]) {
				if (f.component) {
					fields[f.id] = f.component;
					if (f.component.hook && ((f.component.hook == 'f') || (f.component.hook == 'l') || (f.component.hook == 'o') || (f.component.hook == 'e'))) {
						attendeeData[f.id] = $attendee[f.component.hook];
					} else {
						attendeeData[f.id] = $attendee[f.id];
					}
				}
			}
		}

		// console.log({fields});

		let delta = JSON.parse(JSON.stringify(c.content.EN.delta.ops));

		for (const [i,o] of delta.entries()) {
			if (o.insert && o.insert.mention) {
				let ref = o.insert.mention.id;
				if (fields[ref]?.component == 'TicketType') {
					for (const tt of $event?.ticketTypes) {
						if ($attendee.ticketType == tt.ref) {
							delta[i].insert = tt.name.EN;
							break;
						}
					}
				} else if (fields[ref]?.component == 'Country') {
					for (const o of $countryOpts) {
						if (o.ref == attendeeData[ref]) {
							delta[i].insert = o.name.EN;
							break;
						}
					}
				} else if (fields[ref]?.component == 'DateField') {
					delta[i].insert = showDate(attendeeData[ref], 'DD MMMM YYYY');
				} else if (fields[ref]?.options) {
					for (const o of fields[ref].options) {
						if (attendeeData[ref] == o.ref) {
							delta[i].insert = o.name.EN;
							break;
						}
					}
				} else {
					delta[i].insert = attendeeData[ref];
				}
			}
		}

		const converter = new QuillDeltaToHtmlConverter(delta, {
			customCssClasses: (op) => {
				if (op.attributes.upperCase) {
					return 'uppercase';
				}
			}
		});

		converter.renderCustomWith(function(customOp, contextOp){
			if (customOp.insert.type === 'lineBreak') {
				return `<br/>`;
			}
		});

		html = converter.convert();

		// console.log('before',c.content.EN.html);
		// console.log('after',html);

		mounted = true;

	});

</script>

{#if mounted}
	<section class="component { c.component } { c.alignment } { c.width }" use:cssVars={cVars}>
		<div class="textcontent">
			<div>
				{@html html}
			</div>
		</div>
	</section>
{/if}